import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueTypedJs from 'vue-typed-js'
import MasonryWall from '@yeger/vue2-masonry-wall'


Vue.config.productionTip = false


console.log(MasonryWall)

Vue.use(VueTypedJs)
Vue.use(MasonryWall)

Vue.config.ignoredElements = [
  'model-viewer'
]

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
