/* eslint-disable */

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import About from '@/components/about.vue'
import Statement from '@/components/artistStatement.vue'
import Landing from '@/components/landing.vue'
import Navigation from '@/components/navigation.vue'
import SoStrange from '@/components/SoStrange.vue'
import PossibilitiesLife from '@/components/PossibilitiesLife.vue'
import AISoundscapes from '@/components/AISoundscapes.vue'
import Suggested from '../views/Suggested.vue'
import IgnisFaatus from '@/components/IgnisFaatus.vue'
import ImagesOfDivine from '@/components/ImagesOfDivine.vue'
import NewTrajectories from '@/components/NewTrajectories.vue'
import Synthetic from '@/components/Synthetic.vue'
import Memories from '@/components/Memories.vue'
import AlternativeRealities from '@/components/AlternativeRealities.vue'
import ConnectedNest from '@/components/ConnectedNest.vue'
import LivingNoise from '@/views/LivingNoise.vue'
import RoomWithView from '@/components/RoomWithView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/statement',
    name: 'artistStatement',
    component: Statement
  },
  {
    path: '/alternative',
    name: 'AlternativeRealities',
    component: AlternativeRealities
  },
  {
    path: '/possibilities',
    name: 'PossibiltiesOfLife',
    component: PossibilitiesLife
  },
  {
    path: '/familiar',
    name: 'SoStrange',
    component: SoStrange
  },
  {
    path: '/aisoundscapes',
    name: 'AISoundscapes',
    component: AISoundscapes
  },
  {
    path: '/suggested',
    name: 'Suggested',
    component: Suggested
  },
  {
    path: '/ignisfaatus',
    name: 'Ignis Faatus',
    component: IgnisFaatus
  },
  {
    path: '/imagesofdivine',
    name: 'Images Of The Divine',
    component: ImagesOfDivine
  },
  {
    path: '/newtrajectories',
    name: 'New Trajectories',
    component: NewTrajectories
  },
  {
    path: '/connectednest',
    name: 'connected / nest',
    component: ConnectedNest
  },
  {
    path: '/livingnoise',
    name: 'living noise',
    component: LivingNoise
  },
  {
  path: '/roomwithaview',
  name: 'room with a view',
  component: RoomWithView
  },
  {
  path: '/synthetic',
  name: 'synthetic in-betweeness',
  component: Synthetic
  },
  {
  path: '/memories',
  name: 'memories from a distant future',
  component: Memories
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
