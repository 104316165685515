<template>
  <div class="aframe__wrapper">
    <a-scene fog="color: black; type: linear; far: 30" embedded>
      <!--assets at one place for better loading-->
      <a-assets>
        <img src="../assets/black_tile.png" id="grid" crossorigin="anonymous">
        <img id="sky" src="https://img.gs/bbdkhfbzkk/2048x2048,stretch/http://i.imgur.com/WqlqEkq.jpg" crossorigin="anonymous" />
        <a-mixin id="light"
          geometry="primitive: box; width: 0.01; height: 0.01; depth: 0.01"
          material="color: white;
          shader: flat"
          light="color: #cdcab1;
          distance: 10;
          intensity: 2;
          type: point">
      </a-mixin>
        <a-mixin id="static-light"
           geometry="primitive: box;
           width:0.05; height: 40; depth: 0.05"
           material="color: white;
           shader: flat"
           light="color: #cdcab1; distance: 50; intensity: 0.6; type: point"
         ></a-mixin>
        <a-asset-item id="helveticaFont" src="https://data-store.xyz/fonts/Share_Tech_Mono_Regular.json"></a-asset-item>
      </a-assets>

      <!-- CAMERA -->

      <a-entity id="camrig" position="0 0 0" >
       <a-entity id="camera" position="0 0 0" fov="100" camera look-controls wasd-controls camcontrol animation__look="property: camcontrol.rot; startEvents: camlook; dur:500"></a-entity>
      </a-entity>
    </a-scene>

  </div>
</template>

<script>
  /* eslint-disable */
  import 'aframe';
  import 'aframe-text-geometry-component';
  import 'aframe-look-at-component';
  import 'aframe-extras';

  const axios = require('axios');
  const _ = require('underscore');

  AFRAME.registerSystem('trail', {
      schema: {}, // System schema. Parses into `this.data`.
      init: function() {
          // Called on scene initialization.
      },
      trails: {
          haveTrails: [],
      },
      createTrail: function createTrail(object, length, width, resolution, color, offset) {
          // resolution must be less than the length
          if (resolution > length) {
              resolution = length;
          }
          if (!object.userData.trails) object.userData.trails = [];
          const trail = {
              length: Math.round(length),
              width: width,
              resolution: Math.round(resolution),
              trailHistory: [],
              trailVertices: [],
              worldDirection: new THREE.Vector3(),
          }
          object.userData.trails.push(trail);
          // trail geo
          var geometry = new THREE.PlaneGeometry(1, length, 1, resolution);
          var material = new THREE.MeshBasicMaterial({
              color: color,
              side: THREE.DoubleSide,
              wireframe: false,
              transparent: true,
              opacity: 0.2
          }); // opacity: 0.2
          trail.mesh = new THREE.Mesh(geometry, material);
          trail.mesh.position.add(offset);
          this.el.sceneEl.object3D.add(trail.mesh);
          this.trails.haveTrails.push(object);
          // setting frustumCulled to false is important because we move the vertices outside the frustum, not the geometry itself

          trail.mesh.frustumCulled = false;
          // create history and store vertices
          trail.trailHistory = [];
          trail.trailVertices = [];
          for (var i = 0; i < resolution + 1; i++) {
              trail.trailVertices[i] = [];
              //console.logi
          }
          // store vertices based on left or right

          for (var i = 0; i < trail.trailVertices.length; i++) {

              trail.trailVertices[i][0] = trail.mesh.geometry.attributes.position.array[i * 2]
              trail.trailVertices[i][1] = trail.mesh.geometry.attributes.position.array[i * 2 + 1]

          }
      },
      updateTrailHistory: function updateTrailHistory(object) {
          object.userData.trails.forEach(trail => {
              object.getWorldDirection(trail.worldDirection);
              trail.trailHistory.push([object.position.x, object.position.y, object.position.z, trail.worldDirection.x, trail.worldDirection.z]);
              if (trail.trailHistory.length > trail.length) {
                  trail.trailHistory.shift();
              }
          });
      },
      updateTrails: function updateTrails() {
          for (let i = 0; i < this.trails.haveTrails.length; i++) {
              const object = this.trails.haveTrails[i];
              this.updateTrailHistory(object);
              object.userData.trails.forEach(trail => {
                  for (var j = 0; j < trail.trailVertices.length; j++) {
                      var index = Math.round(trail.trailHistory.length / trail.resolution * j);
                      if (index === trail.trailHistory.length) {
                          index = trail.trailHistory.length - 1;
                      }
                      var pos = trail.trailHistory[index];
                      // custom the shape changing this width parameter
                      var width = THREE.Math.mapLinear(j, 0, trail.trailVertices.length, 0, 1) * trail.width / 2;
                      if (typeof pos != "undefined") {
                        try {
                          //console.log(`trailVertices: ${trail.trailVertices}`)
                          // update vertices using a "2D cross product"
                          // one side of the trail, left or right
                          trail.trailVertices[j][0].x = pos[0] - pos[4] * width;
                          trail.trailVertices[j][0].y = pos[1];
                          trail.trailVertices[j][0].z = pos[2] + pos[3] * width;
                          // the other side of the trail
                          trail.trailVertices[j][1].x = pos[0] + pos[4] * width;
                          trail.trailVertices[j][1].y = pos[1];
                          trail.trailVertices[j][1].z = pos[2] - pos[3] * width;
                        }
                        catch(e){
                          let x = 2
                        }
                      }
                  }
                  trail.mesh.geometry.verticesNeedUpdate = true;
              });
          }
      },
      resetTrail: function resetTrail(object) {
          object.userData.trails.forEach(trail => {
              trail.trailHistory = [];
          });
      },
      tick: function(t, dt) {
          this.updateTrails();
      }
  });

  AFRAME.registerComponent('trail', {
      schema: {
          length: {
              default: 80
          },
          width: {
              default: 0.8
          },
          resolution: {
              default: 18
          }, //must be less than length
          color: {
              default: 'white'
          },
          offset: {
              type: 'vec3'
          }
      },
      multiple: true,
      init: function() {
          this.system.createTrail(this.el.object3D, this.data.length, this.data.width, this.data.resolution, this.data.color, this.data.offset);
      },
      reset: function() {
          this.system.reset(this.el.object3D);
      }
  });

  AFRAME.registerComponent('wireframe', {
     dependencies: ['material'],
     init: function () {
       this.el.components.material.material.wireframe = true;
     }
   });

   AFRAME.registerComponent('model-opacity', {
    schema: {default: 1.0},
    init: function () {
      this.el.addEventListener('model-loaded', this.update.bind(this));
    },
    update: function () {
      var mesh = this.el.getObject3D('mesh');
      var data = this.data;
      if (!mesh) { return; }
      mesh.traverse(function (node) {
        if (node.isMesh) {
          node.material.opacity = data;
          node.material.transparent = data < 1.0;
          node.material.needsUpdate = true;
        }
      });
    }
  });

  AFRAME.registerComponent('camcontrol',{
    schema: {
      rot: { type: 'vec3'} ,
      animactive: { type: 'boolean', default: false } },
    init: function(){
      let self = this;
      this.el.addEventListener('loaded', function(){
        self.cam3d = self.el.object3D.children[0];
        self.cam3d.name = "camera"
        // Clone the camera, used for looking at new targets
        self.dummyCam3d = self.cam3d.clone();
        self.el.object3D.add(self.dummyCam3d);
        // Create a new group. make it a child of the dummyCam
        self.dummyUp = new THREE.Object3D();
        self.dummyCam3d.add(self.dummyUp);
        self.dummyUp.translateY(1.0);
        self.loaded = true;
      });
      this.el.addEventListener('animationcomplete__look', function(){
        self.data.animactive = false;
      });
    },
    update: function(){
      if (this.data.animactive){
        let rot = this.data.rot;
        // console.log('update: rot ', rot);
        let euler = new THREE.Euler( rot.x, rot.y, rot.z, 'ZXY');
        this.cam3d.setRotationFromEuler(euler);
      }

    },
    lookAnimation: function(pos){
      let camRot = this.cam3d.rotation;
      let camWP = new THREE.Vector3();
      this.cam3d.getWorldPosition(camWP);

  //    this.dummyCam3d.position = camWP;

      this.dummyCam3d.lookAt(pos.x, pos.y, pos.z);

      let dumCamRot = this.dummyCam3d.rotation;

      this.data.animactive = true;
      this.el.setAttribute('animation__look', { from: {x: camRot.x, y: camRot.y, z: camRot.z}, to:{x: dumCamRot.x, y:  dumCamRot.y, z: 0} });
      this.el.emit('camlook');
    }
  });

  AFRAME.registerComponent('button3d', {
  		init: function(){
  			let cam = document.querySelector('#camera'); // Get the camera
  			let el = this.el;
              let self = this;
  			this.el.addEventListener('click', function(evt){
  				// Get the position of the button
  				let btnpos = el.getAttribute('position');

  				// Make the camera look at the clicked target immediately.
  				let cam3d = cam.object3D.children[0]; //  .object3D
                  // cam3d.lookAt(btnpos.x, btnpos.y, btnpos.z);

  				// Animate the camera looking at the target.
  				cam.components.camcontrol.lookAnimation(btnpos);
  			});
  		}
  	});

  export default {
    name: 'AframeComp',
    props: ["recVal", "state"],

    //components: { PageTitle, Stage, Footer, NavBar },
    data () {
      return {
        //textAreaWidth: 24,
        textAreaWidth: 34,
        staticLightsCoords: [],
        textVal: this.recVal,
        textSize: 20, // => scale 1 to chosen size
        //elementCount: 500,
        elementCount: 500,
        stateCount: 20,
        minimumElements: 20,
        lightAnimationTime: 1000,
        currentTextId: 0,
        currentTextId: 0,
        camRotation: [],
        lifeTime: 1200,
      }
    },
    methods: {
      sleep: function(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },
      genRandom: function(min, max, neg) {
        let num = Math.floor(Math.random()*max) + min; // this will get a number between 1 and 20;
        if (neg) num *= Math.floor(Math.random()*2) == 1 ? 1 : -1; // this will add minus sign in 50% of cases
        return num
      },
      genGrid: function(){
        //var columns = 2;
        //var rows = 2;
        var columns = 2;
        var rows = 2;
        var cell_spacing = 20;
        //var cell_spacing = 6;
        //var center = {x: -6, y: -6};
        var center = {x: -20, y: -20};

        var totalWidth = columns * cell_spacing;  // 5 * 0.06 = 0.3
        var totalHeight = rows * cell_spacing;
        var leftEdge = center.x - (totalWidth / 2); // 0 - (0.3 / 2) = -0.15;
        var topEdge = center.y - (totalHeight / 2)

        var gridCells = []

        for(var r= 0; r <= rows; r++){
          var leftEdge = center.x - (totalWidth / 2);
          for (var c= 0; c <= columns; c++){
            var x = leftEdge + cell_spacing
            var y = topEdge + cell_spacing
            var cell = {
              x: x,
              y: 3,
              z: y
            }

             leftEdge += cell_spacing;
             this.staticLightsCoords.push(cell);
          }
          topEdge += cell_spacing;
        }
      },
      addStaticLights: function(){
        const scene = document.querySelector("a-scene");
        for (var i = 0; i < this.staticLightsCoords.length;i++){
          const lightEl = document.createElement("a-entity")
          lightEl.setAttribute("mixin", "static-light");
          lightEl.setAttribute("position", `${this.staticLightsCoords[i]['x']} ${this.staticLightsCoords[i]['y']} ${this.staticLightsCoords[i]['z']}`)
          scene.appendChild(lightEl)
        }

      },
      startCreateText: function(val) {
        this.currentTextId = (this.currentTextId + 1) % this.stateCount
        const i = this.currentTextId
        const scene = document.querySelector("a-scene");
        const newEl = this.createText(val, i)
        if (document.contains(document.getElementById(`t${i}`))) { // replace older object
          document.getElementById(`t${i}`).remove();
        }
        scene.appendChild(newEl);
      },
      createText: function(textVal, i) {

        //const x_val = this.genRandom(2,14,true) / 2
        //const y_val = this.genRandom(2,14,true) / 2
        const x_val = this.genRandom(2,this.textAreaWidth,true) / 2
        const y_val = this.genRandom(2,this.textAreaWidth,true) / 2
        const temp_z_val = this.genRandom(1,4,false)

        let y_rot = this.genRandom(0,5,false) // val will be multiplied by 90, to get 0 90 180 270 degrees
        y_rot *= 90

        let z_rot_choice = this.genRandom(0,2,false) // rotation should be either 0 degrees or 90 degrees

        let z_val
        let rot_val
        if (z_rot_choice == 0) { // the rotation should be either 0 or 90 degrees, if 90, then have different position for Z axis
          z_val = temp_z_val
          rot_val = 0
        }
        else {
          z_val = -1
          rot_val = 90
        }

        let c = 0
        let scale = this.genRandom(2, this.textSize)

        const model = document.createElement("a-entity")

        model.setAttribute("class", `text`)
        model.setAttribute("id", `t${i}`)
        model.setAttribute("position", `${x_val} ${z_val} ${y_val}`);
        model.setAttribute("scale", `${scale} ${scale} ${scale}`);
        model.setAttribute("rotation", `0 ${y_rot} ${rot_val}`);
        model.setAttribute("material", "color: white")
        //model.setAttribute("material", "color: #cdcab1")
        //model.setAttribute("look-at","[camera]")
        model.setAttribute("text-geometry", `value: ${textVal}; font: #helveticaFont; size: 0.2`)
        model.setAttribute("animation", {
          property: "model-opacity",
          dur: 500,
          easing: "linear",
          from: 0,
          to: 1,
         // delay: 1000+(i*100)
        })
        return model
      //  scene.appendChild(model);
      },
      moveCam: function(pos) {
        console.log(pos)
        //let entity = document.getElementById(`t${i}`);
        //const pos = entity.getAttribute('position')
        const cam = document.getElementById("camera")
        cam.components.camcontrol.lookAnimation({x: pos[0], y: 0, z: pos[2]});
      },
      startScene: async function() {
        for (var i = 0; i < 50; i++) {
          this.createText(i)
          await this.sleep(1000)
        }
      },
      animateLight: function(model_id){
        const model = document.createElement("a-entity")
        const model_light = document.createElement("a-entity")

        const x_val = this.genRandom(5, 10, false);
        const y_val = this.genRandom(5, 10, false);
        const z_val = this.genRandom(0, 3, false);

        model.setAttribute("id", model_id)
        model.setAttribute("trail", 'length:50; width: 0.1; resolution:10');
        model.setAttribute("position", `${x_val} 0 ${y_val}`)
        model.setAttribute("animation", {
          property: "position",
          dur: this.lightAnimationTime,
          easing: "linear",
          to: `-${x_val} ${z_val} -${y_val}`,
        })
        model_light.setAttribute("mixin", "light")
        model.appendChild(model_light)

        return model
      },
      startAnimateLight: async function() {
        const scene = document.querySelector("a-scene");
        // add new light and animate it
        var model_id = this.genRandom(0,1000,false)
        const lightEl = this.animateLight(model_id);
        scene.appendChild(lightEl);

        await this.sleep(this.lightAnimationTime)

        if (document.contains(document.getElementById(model_id))) {
          document.getElementById(model_id).remove();
        }
        this.currentLightId = model_id

      },
      getRandom: function(min, max) {
          return Math.random() * (max - min) + min;
      },

      killAmodel: function(){
        let self = this
        setInterval( () => {
          try{
            let words = document.querySelectorAll('.text')
            if (words.length > self.minimumElements) {
              let word = words[0]
              word.setAttribute("animation", {
                property: "model-opacity",
                dur: 1000,
                easing: "linear",
                from: 1,
                to: 0,
              })
              word.remove()
            }
          }
          catch(e){
            console.log(e)
          }
        },self.lifeTime)
      }
    },
    watch: {
      recVal: function(val) {
        this.startCreateText(val)
        this.startAnimateLight()
      },
      state: function(val) {
        const stateVals = val.slice(Math.max(val.length - this.elementCount, 0))
        for (var i = 0; i < stateVals.length; i++) {
          this.startCreateText(stateVals[i])
        }
      }
    },
    mounted(){
      this.genGrid()
      this.addStaticLights()
      this.killAmodel()
    }
  }
</script>
<style lang="scss" scoped>
  .aframe__wrapper {
    height: 100%;
    background: black;
  }
</style>
