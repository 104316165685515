<template>
  <div class="page">
    <Navigation class="navigation" />
    <div class="container">

      <div class="wrapper">
        <h1 class="heading">Living Noise</h1>
        <div class="desc__wrapper">
          <h2 class="sub-heading">Realtime Web VR, 2020</h2>
          <p class="desc">
            "Living Noise" is a realtime WebVR project in which I've examined a phenomenon called "The Internet Bakcground Noise". In the IT industry it is a known fact the there always is a constant malicious activity in the accessible internet that is created by the automated bots. These bots continuously scan the accessible devices on the internet, looking for known vulnerabilities and attempting to break their way into the devices. For this project, I've set up a so-called "honeypot" server in which I monitor these malicious activities. By using pre-defined lists of passwords, the bots are trying to gain access to my honeypot server. The passwords that are used, are displayed in the WebVR environemnt in realtime. The speach is generated by supplying the passwords to a text-to-speach AI algorithm that uses the accent associated with the geolocation that the attack is carried out from.
  <br /> <br />
            In the below WebAR environemnt, you can walk around using mouse and WASD keys.
          </p>
        </div>
      </div>

      <LivingNoiseTerminal />
      <LivingNoiseMain />

    </div>
  </div>
</template>

<script>
import Navigation from '@/components/navigation.vue'
import LivingNoiseTerminal from '@/components/LivingNoiseTerminal.vue'
import LivingNoiseMain from '@/components/LivingNoiseMain.vue'

export default {
  name: 'LivingNoise',
  components:{
    Navigation,
    LivingNoiseMain,
    LivingNoiseTerminal
  },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .container {
    width: auto;
    margin-bottom: 80px;
    background: black;
  }
  .livingNoise.container {
    width: 1920px;
    justify-content: center;
  }
  .wrapper {
    grid-template-rows: auto auto auto;
  }
  .first.img {
    grid-column: 7 / 10;
    grid-row: 1 / 3;
  }
  .second.img {
    grid-column: 1 / 13;
    grid-row: 3;
  }
  .heading {
    grid-column: 4 / 8;
    grid-row: 1;
    align-self: center;
    text-align: left;
  }
  .desc__wrapper {
    grid-column: 4 / 8  ;
    grid-row: 2;
    text-align: left;
  }
</style>
