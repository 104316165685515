<template>
  <div id="overview-main" class="section">
    <div class="img__wrapper">
       <!--<h4 class="caption">Most popular labels for Instagram training set</h4> -->
        <div id="colourPalett" class="colpal"></div>

    </div>

     <div class="detailed__wrapper">
      <!-- <h4 class="caption">Most popular labels for Instagram training set</h4> -->
        <div class="all_labels">
          <div class="entry" v-for="(name,index) in sortScores.slice(0,330)" v-bind:key="index" >
            <h3> {{name[0]}}: <span class="score">{{name[1]}}%</span></h3>
          </div>
        </div>
      </div>
  </div>

</template>

<script>
/* eslint-disable */
//import PageTitle from '@/components/PageTitle.vue'
const axios = require('axios');
const html2canvas = require('html2canvas');

export default {
  name: 'SuggestedImgAnalysis',
  components: {

  },
  data () {
    return {
      serverIp: "https://jurgispeters.xyz",
      sorted: {},
      toPrint: [],
      classifications: [],
      imgSrc: "",
      colourPalett: [],
      rgbColours: [],
    }
  },
  methods: {
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    },
    lookupData: function(){
      let app = this
      axios.get(`${app.serverIp}:3005/`)
      .then(function (response) {
        // handle success
        response.data.forEach(element => {
          let values = {
            'code': element['code'],
            'scores': element['score'],
            'descriptions': element['description'],
            'colours': element['colours']
          }
          app.classifications.push(values)
        });
      //  console.log(`classifications: ${JSON.stringify(app.classifications)}`)
        app.sortData()
        app.sortColours()
        app.colourElements()
        app.playAnimation()
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
    },
   rgbToHex: function(r, g, b) {
      r = r.toString(16);
      g = g.toString(16);
      b = b.toString(16);
      if (r.length == 1)
        r = "0" + r;
      if (g.length == 1)
        g = "0" + g;
      if (b.length == 1)
        b = "0" + b;

      return "#" + r + g + b;
   },
    sortColours: function(){
      let app = this
      this.classifications.forEach((item, i) => {
        let cols = item['colours'].split(',')
        // loop over each rgb colour and convert to HEX
        for (let c = 0; c < cols.length; c+=4) {
          let r = parseInt(cols[c])
          let g = parseInt(cols[c+1])
          let b = parseInt(cols[c+2])
          let score = cols[c+3]
          let hexCol = app.rgbToHex(r,g,b)
          app.colourPalett.push([hexCol, score])
        //  app.rgbColours.push([r,g,b])
        }
      });
/*
      let sortedCols = this.sortColors(this.rgbColours)
      sortedCols.forEach((item, i) => {
        let hexCol = app.rgbToHex(item[0], item[1], item[2])
        app.colourPalett.push(hexCol)
      });
*/
    //  console.log(sortedCols)
      //this.colourElements()

    },
    colorDistance: function(color1, color2) {
        // This is actually the square of the distance but
        // this doesn't matter for sorting.
        var result = 0;
        for (var i = 0; i < color1.length; i++)
            result += (color1[i] - color2[i]) * (color1[i] - color2[i]);
        return result;
    },
    sortColors: function(colors) {
        // Calculate distance between each color
        var distances = [];
        for (var i = 0; i < colors.length; i++) {
            distances[i] = [];
            for (var j = 0; j < i; j++)
                distances.push([colors[i], colors[j], this.colorDistance(colors[i], colors[j])]);
        }
        distances.sort(function(a, b) {
            return a[2] - b[2];
        });

        // Put each color into separate cluster initially
        var colorToCluster = {};
        for (var i = 0; i < colors.length; i++)
            colorToCluster[colors[i]] = [colors[i]];

        // Merge clusters, starting with lowest distances
        var lastCluster;
        for (var i = 0; i < distances.length; i++) {
            var color1 = distances[i][0];
            var color2 = distances[i][1];
            var cluster1 = colorToCluster[color1];
            var cluster2 = colorToCluster[color2];
            if (!cluster1 || !cluster2 || cluster1 == cluster2)
                continue;

            // Make sure color1 is at the end of its cluster and
            // color2 at the beginning.
            if (color1 != cluster1[cluster1.length - 1])
                cluster1.reverse();
            if (color2 != cluster2[0])
                cluster2.reverse();

            // Merge cluster2 into cluster1
            cluster1.push.apply(cluster1, cluster2);
            delete colorToCluster[color1];
            delete colorToCluster[color2];
            colorToCluster[cluster1[0]] = cluster1;
            colorToCluster[cluster1[cluster1.length - 1]] = cluster1;
            lastCluster = cluster1;
        }

        // By now all colors should be in one cluster
        return lastCluster;
    },
    colourElements: function(){
      let sorted = this.sortArray(this.colourPalett)
      //sorted = sorted.slice(0, 14000)
      let rootEl = document.getElementById("colourPalett")
      for (let i = 0; i < sorted.length; i++) {
        let el = document.createElement('div');;
      	el.style.backgroundColor = sorted[i][0];
        el.classList.add("col-els")
        //el.textContent  = sorted[i][0]
      	rootEl.appendChild(el);
      }
    },
    sortArray: function(arr){
      let app = this
      return arr.sort(function(a, b) {
        return b[1] - a[1];
      });
    },
    sortData: function(){
      let app = this
      // loop over each description and find out total amount and scores of it
      this.classifications.forEach((item, i) => {
        let desc = item['descriptions'].split(',')
        let scores = item['scores'].split(',')
        desc.forEach((item2, i) => {
          //console.log(item2)
          if (!(item2 in app.sorted)) app.sorted[item2] = []
          app.sorted[item2].push(scores[i])
        });
      })
      for (const item in app.sorted) {
        let scores = app.sorted[item]
        let score
        // if uniq desc - only one score
        if (scores.length < 2) score = scores
        else {
          let pScores = scores.map(function(item) {
            return parseFloat(item, 10);
          })
          score = pScores.reduce((a, b) => a + b, 0) // get total
        }
        score = Math.round(score * 1000) / 1000
        app.toPrint.push([item,score])
      }
      app.calculatePercents()
    },
    calculatePercents: function(){
      var total = 0
      for (var i = 0; i < this.toPrint.length; i++) {
        if (isNaN(this.toPrint[i][1])) console.log(this.toPrint[i][1])
        else total += this.toPrint[i][1]
      }
      for (var i = 0; i < this.toPrint.length; i++) {
        let percent = (this.toPrint[i][1] / total) * 100
        this.toPrint[i][1] = Math.round(percent * 100) / 100
      }
    },
    playAnimation: async function(){
      let len = this.classifications.length
      for (let i = 0; i < len; i++) {
        try {
          this.imgSrc = require(`../assets/saved_imgs/${this.classifications[i]['code']}.jpg`)
          await this.sleep(500)
        }
        catch {
          console.log(`err`)
        }
      }
      this.playAnimation()
    },
  },
  computed: {
    sortScores: function(){
      let app = this
      return this.toPrint.sort(function(a, b) {
        return b[1] - a[1];
      });
    }
  },
  async mounted(){
    await this.lookupData()
  //  this.playAnimation()
  }

}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.suggestedImgAnalysis {
  width: 1920px !important;
  overflow: hidden;
  height: 600px;
//  display: flex;
//  flex-wrap: wrap;
//  justify-content: center;
//  height: 100vh;
//  width: 1200px;
//  margin: 0 auto;

  .caption {
    font-size: 0.8em;
    font-weight: normal
  }

  @media only screen and (max-width: 1300px) {
   #overview-main {
     width: 100vw;
   }
  }
  .img__wrapper {
    width: 30%;
  }
  .img__container img {
    width: 100%;
  }
  @media only screen and (max-width: 1300px) {
   .img__wrapper {
     width: 350px;
     height: 550px;
   }
  }
  .detailed__wrapper {
    width: 60%;
    margin-left: 40px;
  }
  @media only screen and (max-width: 1300px) {
   .detailed__wrapper {
     width: 64vw;
     margin-left: 25px;
   }
  }
  #colourPalett {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
  }
  @media only screen and (max-width: 1300px) {
   #colourPalett {
     width: 100%;
   }
  }
  .col-els {
    width: 7px!important;
    height: 7px!important;
  }
  .all_labels {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 60px;
    justify-content: center;
  }
  .entry {
    display: inline-block;
    width: 24%;
  }
  @media only screen and (max-width: 1300px) {
   .entry {
     width: 25%;
   }
  }
  .entry h3 {
    margin: 0;
    padding: 0;
    color: white;
    font-weight: 500;
    text-align: left;
    font-size: 0.55em;
    font-family: Courier;
  }
  .entry span {
    color: #6f7888;
  }
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
}
</style>
