<template>
  <div class="page">
    <Navigation class="navigation" />
    <div class="container fullscreen">
      <div class="wrapper">
        <div class="top__part">
          <!--<img src="../assets/soStrange/nn4.png" class="first img"/> -->
          <div class="text__wrapper">
            <h1 class="heading">Possibilities of Life</h1>
            <h2 class="sub-heading">2022 visuals</h2>
            <p class="desc">
              Visuals and scenography for the contemporary vocal chamber music concert “Possibilities of Life” that took place in the cultural space "Tu Jau Zini Kur" in Riga, Latvia. <br/> <br />The concert title suggests a speculation about how we as humans can continue living on planet Earth and what would have to change for a successful symbiosis. The concert consists of four thematic blocks exploring different aspects of the theme, each with its own conceptual and artistic direction.
              <br/> <br/>
              Musicians participating in the conert:<br/><br/>
              Armands Silins - voice<br/>
              Evalds Lazarevics - piano<br/>
              Platons Buravickis - electronics<br/>
            </p>
          </div>
          <img class="img-poster img" src="../assets/possibilitiesLife/meness.png" />
        </div>

        <video ref="videoH1" class="video video-head first vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
        <div class="block first">
          <img src="../assets/possibilitiesLife/IMG_1154.png" class="img"/>
          <p class="block_desc"> This block consists of Platons Buravickis newly created song cycle with the same name as the concert “Possibilities of Life”, the cycle is based on texts from American transcendentalist Ralph Waldo Emerson, Irish contemporary poet Paul Muldoon and Russian scientist Vladimir Verandsky. <br /><br />

AI created and manipulated images serve as the basis for the visuals. Here the melody and voice dictates the sequence of the images as well as their level of distortion. The themes found in the AI images all explore different aspects of our impact here on Earth, ranging from urban landscapes to genetic modifications of plants.
 </p>
          <video ref="videoRec1" class="video video-recording vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
        </div>

        <video ref="videoH2" class="video video-head second vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
        <div class="block second">
          <img src="../assets/possibilitiesLife/IMG_1172.png" class="img"/>
          <p class="block_desc">
            The second part of the concert consists of four etudes by Beaudoin, three of which are composed for piano and one for piano and voice. All etudes are composed in the Beaudoin’s microtiming approach. <br/><br/>


  As a reference to the purely mathematical aspect of Beaudoin’s microtiming, the visuals for this part mainly consists of the most fundamental component to any mathematical system - numbers. And indeed, numbers arranged and rearranged can convey completely different and new meanings. Here the visuals range from simple binary representations to more complex systems and eventually culminate as geometrical vertexes radiating from a full moon.

          </p>
              <video ref="videoRec2" class="video video-recording vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
        </div>

        <video ref="videoH3" class="video video-head third vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
        <div class="block third">
          <img src="../assets/possibilitiesLife/evalds.jpg" class="img"/>
          <video ref="videoRecWeh" class="video weh video-recording vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
          <p class="block_desc"> 01100001 01110010 01100101 00100000 01110100 01101000 01100101 01110010 01100101 00100000 01100001 01101110 01111001 00100000 01110000 01101111 01110011 01110011 01101001 01100010 01101001 01101100 01101001 01110100 01101001 01100101 01110011 00100000 01101111 01100110 00100000 01101100 01101001 01100110 01100101 00100000 01101100 01100101 01100110 01110100 00100000 01101000 01100101 01110010 01100101 00100000 01101111 01101110 00100000 01000101 01100001 01110010 01110100 01101000 00111111  </p>
          <video ref="videoRec3" class="video video-recording vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
        </div>
        <video ref="videoH4" class="video video-head fourth vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
        <div class="block fourth">
          <video ref="videoRec4" class="video video-recording vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
          <p class="block_desc"> The final part of the concert consisted of Gundegas Smites song cycle “Three Songs by William Shakespeare” sung by Armands Silins and accompanied by Evalds Lazarevics on piano.
Underlining the mythical and sensual aspect of the last part, the visuals here represent an ever changing sea of life whose waves are in constant flux. With more dominant sounds becoming audible, a morphing red moon becomes visible.
 </p>
          <video ref="videoRec4" class="video video-recording vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/navigation.vue'
import videojs from 'video.js';
import canAutoPlay from 'can-autoplay';

export default {
  name: 'PossibilitiesLife',
  props: {
    msg: String
  },
  components: {
    Navigation
  },
  data() {
    return {
      player: null,
      autoplay: true,
      videosAuto: [
         {
          ref: 'videoH1',
          autoplay: true,
          controls: false,
          mute: true,
          loop: true,
          sources: [
            {
              src: "https://data-store.xyz/video/possibilitiesLife/portfolio_PLATONS2.mov",
              type: "video/mp4",
            },
          ]
        },
        {
          ref: 'videoH2',
          autoplay: true,
          controls: false,
          mute: true,
          loop: true,
          sources: [
            {
              src: "https://data-store.xyz/video/possibilitiesLife/portfolio_strip_evalds_ASCII.mov",
              type: "video/mp4",
            },
          ]
        },
        {
          ref: 'videoH3',
          autoplay: true,
          controls: false,
          mute: true,
          loop: true,
          sources: [
            {
              src: "https://data-store.xyz/video/possibilitiesLife/portfolio_strip-evalds.mov",
              type: "video/mp4",
            },
          ]
        },
        {
          ref: 'videoH4',
          autoplay: true,
          controls: false,
          mute: true,
          loop: true,
          sources: [
            {
              src: "https://data-store.xyz/video/possibilitiesLife/portfolio_armands.mov",
              type: "video/mp4",
            },
          ]
        },

      ],
      videoRec1Opt: {
        controls: true,
        mute: false,
        loop: false,
        sources: [
          {
            src: "https://data-store.xyz/video/possibilitiesLife/fragmenti-platons.mp4",
            type: "video/mp4",
          },
        ]
      },
      videoRec2Opt: {
        controls: true,
        mute: false,
        loop: false,
        sources: [
          {
            src: "https://data-store.xyz/video/possibilitiesLife/fragmenti-ascii.mp4",
            type: "video/mp4",
          },
        ]
      },
      videoRec3Opt: {
        controls: true,
        mute: false,
        loop: false,
        sources: [
          {
            src: "https://data-store.xyz/video/possibilitiesLife/fragmenti-evalds.mp4",
            type: "video/mp4",
          },
        ]
      },
      videoRec4Opt: {
        controls: true,
        mute: false,
        loop: false,
        sources: [
          {
            src: "https://data-store.xyz/video/possibilitiesLife/fragmenti-armands.mp4",
            type: "video/mp4",
          },
        ]
      },
      videoRecWehOpt: {
        controls: true,
        mute: false,
        loop: false,
        sources: [
          {
            src: "https://data-store.xyz/video/possibilitiesLife/fragmenti-wem.mp4",
            type: "video/mp4",
          },
        ]
      }

    }
  },
  methods: {
    initVideos: function(){
      let self = this
      for (let i = 0; i < this.videosAuto.length; i++){
        let item = this.videosAuto[i]
        if (!this.autoplay) {
          item.controls = true
          console.log(`1212 controls true`)
        }
        self.player = videojs(this.$refs[item.ref], item)
      }

      this.player = videojs(this.$refs.videoRec1, this.videoRec1Opt,
          function onPlayerReady() {
              console.log('onPlayerReady', this);
          })
      this.player = videojs(this.$refs.videoRec2, this.videoRec2Opt,
          function onPlayerReady() {
              console.log('onPlayerReady', this);
          })
      this.player = videojs(this.$refs.videoRec3, this.videoRec3Opt,
          function onPlayerReady() {
              console.log('onPlayerReady', this);
          })
      this.player = videojs(this.$refs.videoRec4, this.videoRec4Opt,
          function onPlayerReady() {
              console.log('onPlayerReady', this);
          })
      this.player = videojs(this.$refs.videoRecWeh, this.videoRecWehOpt,
          function onPlayerReady() {
              console.log('onPlayerReady', this);
          })
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  mounted(){
    let self = this
    setTimeout(function(){
      canAutoPlay.video().then(function(obj) {
        if (obj.result === true) {

          self.autoplay = true
          // Can autoplay
        } else {
          console.error(`cant play`)
          self.autoplay = false
        }
        self.initVideos()
      });
    }, 500)
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$red: #6c0909;

  .container.fullscreen {
    width: 100vw;
  }
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .top__part, .block {
    width: 1200px;
  }
  .top__part {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    margin-top: 100px;
  }
  .text__wrapper {
    grid-column: 8/12;
    margin-top: 171px;

    .heading, .sub-heading, .desc {
      text-align: right;
    }

    .desc {
      text-align: justify;
    }
  }

  .img-poster {
    grid-column: 2/7;
    grid-row: 1;
  }

  .video-head {
    width: 100%;
    margin-top: 150px;
  }

  .block {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    margin-top: 100px;
  }
  .block {
    .img {
      grid-column: 2/6;
    }
    .video.weh {
      grid-column: 7/12;
      grid-row: 2;
    }

    .block_desc {
      grid-column: 8/12;
      text-align: right;
      grid-row: 1;
    }

    .video-recording {
      grid-row: 1;
      align-self: end;
      grid-column: 7/12;
    }
  }
  .block.third {
    .img {
      grid-row: 2;
    }
    .block_desc {
      grid-column: 2/6;
      align-self: center;
      text-align: center;
    }
    .video.weh{
      grid-row: 2;
    }
  }

  .block.fourth {
    .block_desc {
      grid-column: 2/6;
      align-self: center;
    }
  }
</style>
