<template>
  <div class="section">
    <p class="desc">
        By using the collected images as a training set for GAN family Machine Learning algorithm, a new endless visual content was created. During the training process, with each iteration the algorithm becomes more aware of the structural relationships and patterns that are encoded in each image, and thus it becomes more capable of reproducing them in a contextually new form. The results are visually similar to the original training data - but their individual meaning is lost. This results in an endless sequence of purely aesthetical imagery that does not challenge the viewer but rather serves as a pleasant pastime.
    </p>
    <video ref="videoPlayer" class="video vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
  </div>
</template>

<script>
import videojs from 'video.js';
import canAutoPlay from 'can-autoplay';


export default {
  name: 'SuggestedBirth',
  props: {
    msg: String
  },
  data() {
    return {
      player: null,
      videoOptions: {
        autoplay: 'muted',
        controls: false,
        loop: true,
        sources: [
          {
            src: "https://data-store.xyz/video/suggested/training_video4.mov",
            type: "video/mp4",
          },
        ]
      }
    }
  },
  methods: {},
  mounted(){
    let self = this
    canAutoPlay.video().then(function(obj) {
      if (obj.result === true) {
        // Can autoplay
      } else {
        self.videoOptions.controls = true
      }
      self.player = videojs(self.$refs.videoPlayer, self.videoOptions,
        function onPlayerReady() {
          console.log('onPlayerReady', this);
        })
    });

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../node_modules/video.js/dist/video-js.css';

  .section {
    display: grid !important;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    column-gap: 25px;
    row-gap: 15px;
  }
  .desc {
    text-align: left;
    grid-column: 1 / 4;
  }
  .video {
    grid-column: 5 / 13;

  }
</style>
