<template>
    <div id="terminal-page" class="page__wrapper">
      <!--<div class="btn" @click="start()"><h4>start?</h4></div>-->
      <div class="console__wrapper">
        <div class="terminal-line" v-for="(item,i) in getLineState" :key="i">
            <h4 class="timestamp"> {{ item.timestamp }} | </h4>
            <h4 class="ip"> {{ item.ip }} </h4>
        </div>

        <div class="terminal-line" v-for="(item,i) in getLineData" :key="`${i}-${item.timestamp}`">
          <vue-typed-js :strings="[`${item.timestamp} | ${item.ip}`]" :typeSpeed="0.5">
            <h4 class="typing"></h4>
          </vue-typed-js>
        </div>
      </div>
  </div>
</template>

<script>
/* eslint-disable */
const axios = require('axios');
const _ = require('underscore');
 // import PageTitle from '@/components/PageTitle.vue'

export default {
  name: 'LivingNoiseTerminal',
  data () {
    return {
  //    serverIp: "91.205.175.216",
      serverIp: "https://living-noise.xyz",
      currentPasw: '',
      paswState: [],
      ready: false,
      data: [
       {
         'timestamp': "x",
         'ip': "",
         'pasw': ""
       }
      ],
      state: [
       {
         'timestamp': "x",
         'ip': ""
       }
      ],
      lineAmount: 2,
    }
  },
  methods: {
    scrollToBottom: function(){
      let el = document.querySelector(".console__wrapper")
      el.scrollTop = el.scrollHeight;
    },
    recreateState: function(){
      let app = this
      //axios.get('http://91.205.175.216:3000/state')
      axios.get(`${app.serverIp}:3000/state`)
        .then(function (response) {
          // handle success
          response.data.slice().forEach(element => {
            let values = {
              'timestamp': element.split(',')[0],
              'ip': element.split(',')[1]
            }
            let pasw = element.split(',')[2]

            app.state.push(values)
            app.paswState.push(pasw)
          });
          app.scrollToBottom()
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
    },
    readLine: function() {
      let app = this
      axios.get(`${app.serverIp}:3000`)
      //axios.get('http://91.205.175.216:3000')
        .then(function (response) {
          // handle success
          const values = {
            'timestamp': response.data.split(',')[0],
            'ip': response.data.split(',')[1],
            'pasw': response.data.split(',')[2]
          }
          const lastEl = app.data[app.data.length - 1]

          if (values['timestamp'] !== lastEl['timestamp']) { // there is a new entry
            app.getAudio()

            app.data.push(values)

            app.currentPasw = values['pasw']

            setTimeout(function(){
              app.scrollToBottom()
            },100)
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
    },
    getAudio: async function() {
      let app = this
      const { data } = await axios.get(`${app.serverIp}:3000/audio`, {
        responseType: 'arraybuffer',
        headers: {
            'Content-Type': 'audio/wav'
        }
      });
      const blob = new Blob([data], {
                type: 'audio/wav'
            });
      const url = URL.createObjectURL(blob);
      const audio = new Audio(url);

      this.manipulateAudio(audio)
    },
    manipulateAudio: function(audio) {
      let ctx = new AudioContext();

      let source = ctx.createMediaElementSource(audio);

      var delay = ctx.createDelay();
      delay.delayTime.value = 0.5;

      var feedback = ctx.createGain();
      feedback.gain.value = 0.4;

      var filter = ctx.createBiquadFilter();
      filter.frequency.value = 1000;

      delay.connect(feedback);
      feedback.connect(filter);
      filter.connect(delay);

      source.connect(delay);
      source.connect(ctx.destination);
      delay.connect(ctx.destination);

    //  audio.play()
  },
  start: function() {
    this.ready = true;
    let getData = setInterval(() => this.readLine(), 500);
  }
},
computed: {
  getLineData: function() {
     return this.data.filter(i => i.timestamp !== 'x')
  },
  getLineState: function() {
     return this.state.filter(i => i.timestamp !== 'x')
  }
},
  mounted(){
    this.recreateState()
    let getData = setInterval(() => this.readLine(), 500);
  }
}
</script>
<style>
#terminal-page .typed-cursor {
  font-size: 9px;
}
</style>

<style scoped>
.element::-webkit-scrollbar { width: 0 ! important }
.element { overflow: -moz-scrollbars-none; }
.element { -ms-overflow-style: none; }
 h4 {
   color: #818076;
   font-family: Courier New,Courier,Lucida Sans Typewriter,Lucida Typewriter,monospace;
   font-size: 9px;
 }
 .btn {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   color: #cdcab1;
   border: 1px solid #cdcab1;
   cursor: pointer;
 }
 .btn h4 {
   margin: 15px;
 }
 .page__view {
   display: relative;
 }

.page__wrapper, .console__wrapper {
  width: 1200px;
  height: 800px;
}
 .page__wrapper {
   margin-bottom: 1px;
   margin-bottom: 50px;

   overflow: hidden;

   user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */
 }

 .console__wrapper {

   display: flex;
   flex-wrap: wrap;

  overflow-y: scroll;
  overflow-x: hidden;

  padding-right: 20px;

 }
 .controls {
   width: 30%;
   color: white;
 }

 .terminal-line {
   display: flex;
   width: 24.5%;
 }
 .typed-cursor {
   font-size: 9px;
 }
 .terminal-line h4 {
   margin-top: 2px;
   margin-bottom: 2px;
 }
 .timestamp {
   margin-right: 5px;
 }
</style>
