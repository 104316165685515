<template>
  <div class="container fullscreen">
    <Navigation class="navigation" />
    <div class="wrapper">
        <!--<img src="../assets/soStrange/nn4.png" class="first img"/> -->
        <div class="top__part">
          <div class="text__wrapper">
            <h1 class="heading">Alternative Realities</h1>
            <h2 class="sub-heading">Installation, 2022</h2>
            <p class="desc">
              Analysing the Russian invasion in Ukraine, it is clear that the inaccessibility of objective information and outright disinformation has caused a large part of Russian citizens and their supporters elsewhere in the world to live in an alternative reality, where, referring to Orvel, 2 + 2 = 5, and Russian army troops are considered liberators. And in a similar manner  because of the alternative reality narrative, many Russian servicemen truly believed that they were about to fight to liberate rather than to conquer. Thus the information warfare wedged by pro-Russian forces has proved to be an efficient weapon that they have managed to use skillfully.

              “Alternative Realities” looks at how new technology is used to both construct and disseminate the false narrative used by the Kremlin and what are the possible implications. During the project’s research phase, it was established that Russian special forces had used the TikTok platform many times to successfully spread the alternative war narrative. For example, in multiple cases prominent Russian TikTok influencers had shared videos saying identical pro-war phrases, as if reading the same script. In other cases, popular TikTok trends have been used to push the same war narrative to younger audiences.

              Underlying the impact of such disinformation campaigns and the dangers of alternative information spaces, the project presents AI edited images of fallen Russian servicemen.  The images of killed servicemen, which are now forbidden to distribute in Russia, have been found in various Telegram groups. By using AI tools to “clean” the images and delete the personnel, the author has emphasised the use of various smart algorithms in distributing disinformation.
          </p>
          </div>
            <img src="../assets/alternativeRealities/publicitate_1.png" class="head__img first img" />
            <img src="../assets/alternativeRealities/publicitate_2.png" class="head__img second img" />
          </div>
          <div class="screenshot row">
            <img src="../assets/alternativeRealities/screenshot-1.jpg" class="screenshot first" />
            <img src="../assets/alternativeRealities/screenshot-2.jpg" class="screenshot second" />
            <img src="../assets/alternativeRealities/screenshot-3.jpg" class="screenshot third" />

          </div>
          <div class="media__wrapper">
            <div class="img__wrapper">
              <div v-for="item in imgs" :key="item.img" :class="(item.img === 'video')?'video-item':'item'">
                  <video v-if="item.img === 'video'" :key="item.id" ref="videoPlayer" class="video vjs-default-skin vjs-fluid video-js vjs-big-play-centered"></video>
                  <img v-else class="img" :src="item.img"  :key="item.img" data-toggle="tooltip" />
              </div>
            </div>
            <h4 class="caption">Images with the erased soldiers and a video piece with the cleaning-up process  </h4>
          </div>
          <img src="../assets/alternativeRealities/exhibit_2.jpg" class="exhibit__img first img" />
          <img src="../assets/alternativeRealities/exhibit_1.jpg" class="exhibit__img second img" />
          <h4 class="caption second">Exhibition view  </h4>
      </div>
  </div>
</template>

<script>
import Navigation from '@/components/navigation.vue'
import videojs from 'video.js';
import canAutoPlay from 'can-autoplay';

export default {
  name: 'AlternativeRealities',
  props: {
    msg: String
  },
  components: {
    Navigation
  },
  data() {
    return {
      player: null,
      videoOptions: {
        controls: false,
        autoplay: true,
        mute: true,
        loop: true,
        sources: [
          {
            src: "https://data-store.xyz/video/alternativeRealities/video-web.mov",
            type: "video/mp4",
          },
        ]
      },
      imgSrc: "",
      imgs: [
        {id: 1, img: require('../assets/alternativeRealities/soldiers/01.jpg')},
        {id: 2, img: require('../assets/alternativeRealities/soldiers/02.jpg')},
        {id: 3, img:  'video'},
        {id: 4, img:  require('../assets/alternativeRealities/soldiers/04.jpg')},
        {id: 5, img:  require('../assets/alternativeRealities/soldiers/15.jpg')},
      ],
      animSpeed: [1000,5000],
      animate: false,
      stopLoop: false,
    }
  },
  methods: {
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
  },
   padToTwo: function(number){
     if (number<10) { number = ("0"+number).slice(-2); }
     return number;
   },
   prepImgs: function(){
     let app = this
     for (let i = 0; i < 5; i++) {
       if (i !== 2) {
         let ran = this.getRandom(0,91)
         let img = this.padToTwo(ran)
         let imgSrc = require(`../assets/alternativeRealities/soldiers/${img}.jpg`)
         app.imgs[i]['img'] = imgSrc
       }
     }
    this.controlLoop()
   },
   controlLoop: async function() {
    if (this.stopLoop) {
      console.log(`1212 returning`)
      return;
    }
    else {

      let pos = this.getRandom(0,4)
      while (pos == 2) {
        pos = this.getRandom(0,4)
      }
      console.log(`pos: ${pos}`)
      this.changeImg(pos)
      let rsleep = this.getRandom(this.animSpeed[0], this.animSpeed[1])
      await this.sleep(rsleep)
      this.controlLoop()
    }
   },
   changeImg: function(pos){
     let ran = this.getRandom(0,91)
     let img = this.padToTwo(ran)
     let imgSrc = require(`../assets/alternativeRealities/soldiers/${img}.jpg`)
     this.imgs[pos]['img'] = imgSrc
   },
   getRandom: function(min, max) { // min and max included
     return Math.floor(Math.random() * (max - min + 1) + min);
   }
  },
  destroyed(){
     this.stopLoop = true
     console.log("destroyed");
   },
  mounted(){
    let self = this

    canAutoPlay.video().then(function(obj) {
      if (obj.result === true) {
        console.log(`can play! all good!`)
        self.player = videojs(self.$refs.videoPlayer[0], self.videoOptions,
          function onPlayerReady() {
            this.play()
            console.log('onPlayerReady', this);
          })
        // Can autoplay
      } else {
        console.error(`cant autoplay`)
        self.videoOptions.controls = true
        self.player = videojs(self.$refs.videoPlayer[0], self.videoOptions,
          function onPlayerReady() {
            //this.play()
            console.log('onPlayerReady', this);
          })
        // Can not autoplay
      }
    });

    this.prepImgs()
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$red: #6c0909;

  .container.fullscreen {
    width: 100vw;
  }

  .wrapper {
    grid-template-rows: auto auto auto auto auto auto;
    grid-row-gap: 100px;
    margin-bottom: 100px;
    justify-items: center;

    @media only screen and (max-width: 600px) {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
  }
  .top__part {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 25px;
    width: 1600px;
    grid-column: 1/13;
    grid-row: 1;
    margin-bottom: 100px;
    margin-top: 50px;

    @media only screen and (max-width: 600px) {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      row-gap: 25px;
    }
  }
  .text__wrapper {
    grid-column: 1/7;
    text-align: right;
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    margin-left: 50px;

    .heading {
      width: 100%;
      transform: translateY(25px);
    }

    .sub-heading {
      transform: translateY(25px);
    }

    .desc {
      margin-top: 75px;
      text-align: justify;
    }
  }
  .head__img.first {
    grid-column: 7 / 10;
  }
  .head__img.second {
    grid-column: 10 / 13;
    margin-top: 100px;
  }

  .screenshot.row {
    grid-column: 1/13;
    grid-row: 2;
    display: flex;
    margin-bottom: 90px;

    .screenshot {
      width: 350px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .media__wrapper {
    display: grid;
    grid-column: 1/13;
    grid-row: 3;
    column-gap: 25px;
    width: 90%;

    .img__wrapper {
      width: 100%;
      display: flex;
      align-items: center;

      .item, .video-item {
        margin-left: 4px;
        margin-right: 4px;
      }
      .item {
        width: 18%;
      }
      .video-item {
        width: 27%;
        border-bottom:
        10px solid black;
      }
    }
    .caption {
      margin-top: 50px;
    }
  }
  .exhibit__img.first {
    grid-row: 4;
    grid-column: 2/9;
  }
  .exhibit__img.second {
    grid-row: 5;
    grid-column: 3/12;
  }
  .caption.second {
    grid-row: 6;
    justify-self: center;
    justify-items: center;
    grid-column: 1/13;
    margin: 0;
    transform: translateY(-50px);
  }


</style>
