<template>
  <div class="page">

    <div class="statement-container">
      <div class="wrapper">

        <h2 class="title">
          Short Biography
        </h2>
      <p>
        Jurģis is a new media artist and researcher currently based in Helsinki, Finland. He is mainly interested in visual research on the impact of various phenomena created by new technologies and their consequences in society. And as such, one of the main focuses of his work is artificial intelligence (AI), both as a medium and as a conceptual field of research. Current developments in the field of AI have sparked intense debates in society, ranging from how much of critical tasks we entrust to algorithms to what the role of humans as creators will be in the era of AI-generated content. Given his previous experience with various machine learning algorithms and tools, Jurģis believes that the near future will be a time of co-creation between humans and AI, where algorithms will be able to augment the artist's abilities. And although this scenario is very optimistic, the author also stresses the need to maintain the position of a critical observer, thas it capable of reflecting on the consequences that the adoption of such technology might have. In an attempt to see from both perspectives, the author uses new technologies in his practice as tools to create visual stories and speculations about possible future scenarios and the role of people in them.
<br/><br/>
        Jurģis currently is a PhD student and researcher in Tampere University Doctoral Programme of Humans and Technologies. He also has graduated from the Art Academy of Latvia with a Master's degree in Audiovisual Arts, as well as from the University of Birmingham (UK) with a Master's degree (MSc) in Cyber Security and a Bachelor's degree (BSc) in Digital Media Technologies. Having had both an academic and professional experience with working in technology and IT industries, Jurģis often creates his own tools and algorithms where they are used as a means for artistic expression.

      </p>

    </div>
  </div>
</div>
</template>

<script>


export default {
  name: 'Statement',
  props: {
    msg: String
  },
  components: {
  },
  data () {
    return {
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .page {
    height: 100vh;
    background: black;
    display: flex;
    align-items: center;
  }

  .statement-container {
    width: 100%;
  }
  .wrapper {
    display: block;
    display: flex;
    justify-content: center;
    margin-top: 100px;
    flex-wrap: wrap;

  }
  .title {
      width: 20%;
      align-self: center;
  }

  p {
    width: 600px;
    text-align: justify;
  }


</style>
