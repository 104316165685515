<template>
  <div class="main">

    <Landing class="landing" />
    <!--
    <SoStrange class="soStrange" />
    <AISoundscapes class="aiSoudscapes" />
    <Suggested class="suggested" />
    <IgnisFaatus class="ignisFaatus"/>
    <ImagesOfDivine class="imagesOfDivine"/>
    <ConnectedNest class="connectedNest" />
    <LivingNoise class="livingNoise" />
    <RoomWithView class="roomWithView"/>
  -->
  </div>
</template>

<script>
/* eslint-disable */

// @ is an alias to /src

import Landing from '@/components/landing.vue'
import Navigation from '@/components/navigation.vue'
import SoStrange from '@/components/SoStrange.vue'
import AISoundscapes from '@/components/AISoundscapes.vue'
import Suggested from '@/views/Suggested.vue'
import IgnisFaatus from '@/components/IgnisFaatus.vue'
import ImagesOfDivine from '@/components/ImagesOfDivine.vue'
import ConnectedNest from '@/components/ConnectedNest.vue'
import LivingNoise from '@/views/LivingNoise.vue'
import RoomWithView from '@/components/RoomWithView.vue'



export default {
  name: 'Home',
  components: {
    Landing,
    Navigation,
    SoStrange,
    AISoundscapes,
    Suggested,
    IgnisFaatus,
    ImagesOfDivine,
    ConnectedNest,
    LivingNoise,
    RoomWithView
  }
}
</script>
<style lang="scss">
  body {
    margin: 0;
  }
  .container {
    min-height: 100vh;
    width: 1200px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: center;
    margin-top: 150px;

    @media only screen and (max-width: 600px) {
      margin-top: 80px;
    }
  }

  .wrapper {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto auto;
    column-gap: 25px;
    row-gap: 15px;
    margin-bottom: 50px;

    @media only screen and (max-width: 600px) {
      display: flex;
      flex-wrap: wrap;
      margin-left: 40px;
      margin-right: 40px;
      row-gap: 25px;
      margin-bottom: 25px;
    }
  }

  h1, p {
    margin: 0;
  }
  p {
    font-size: 1em;
  }

  .heading {
    font-size: 3em;
  }
  .caption {
    font-size: 0.8em;
    font-style: italic;
    color: #b5a9a9 !important;
    font-weight: normal;

    @media only screen and (max-width: 600px) {
      font-size: 0.6em;
      color: #62eb23 !important;
      margin-top: 5px;
      margin-bottom: 5px;
      font-style: normal;
    }
  }


  .sub-heading{
    font-weight: normal;
    font-size: 0.8em;
    margin-bottom: 18px;

    @media only screen and (max-width: 600px) {
      font-size: 0.7em;
      color: #62eb23 !important;
    }
  }

  .img {
    width: 100%;
  }

</style>
