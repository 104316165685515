<template>
  <div class="page">
    <!--<Navigation class="navigation" />-->
    <div class="container">
    <Navigation class="navigation" />

    <div class="wrapper">
        <!--<img src="../assets/divine/011.png" class="head img"/> -->
        <div class="row first">
          <h1 class="heading">Images of The Divine</h1>
        </div>
        <div class="row second">
          <h2 class="sub-heading">Installations, 2023</h2>
          <p class="desc">
            Throughout history people have viewed technology both as an instrument to connect with the divine - from the ancient bone oracles, to the experiments with electromagnetic frequencies to capture the voices of the dead - as well as a force that is going to bring them closer to a divine state. This belief in technology as a guiding force can be seen in, for example, the Gaian mind ideals that the early internet pioneers and cyberspace buffs promoted in the 1990s. In the very recent times, especially with the explosive growth of various AI algorithms, there is also the prevalent belief that new technology will be able to undo the damage that we have caused on earth and solve the looming climate crisis, and if not that then the new technology will surely be able to transport us away from planet Earth and help humans to colonise Mars. <br />Reflecting on this quasi-religious obsession with technology, "Images of The Divine” explores ways to utilize Generative AI to imagine new gods and goddesses suited for the Age of AI.
          </p>
          <img class="cover__img" src="../assets/divine/s-m-optimized.jpg" />
        </div>


        <div class="row third">
          <img src="../assets/divine/skatlogs-new-small.jpg" class="long img"/>
        </div>
        <h4 class="caption skatlogs"> Large format print for gallery windows</h4>

        <div class="row fourth">
          <img src="../assets/divine/skatlogi-2.jpg" class="img first"/>
          <img src="../assets/divine/skatlogi-1-.jpg" class="img second"/>
        </div>
        <h4 class="caption skatlogs-view"> Large format print at ISSP gallery, Latvia </h4>


        <div class="row fifth">
          <p class="desc">Donec dapibus blandit scelerisque. Nunc sit amet ante ut ante consequat dictum. Donec sit amet consequat risus. Aenean nec diam accumsan, auctor nibh vitae, mattis dolor. Etiam sed consectetur dui. Sed cursus justo dolor, cursus lobortis augue laoreet eget. Phasellus ultricies, elit ut egestas pulvinar, felis leo convallis dolor, ac imperdiet </p>
          <video ref="videoPlayerHandsView" class="videoHandsView vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
          <div class="video-hands">
            <video ref="videoPlayerHands" class="videoHands vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
          </div>
        </div>
        <h4 class="caption skatlogs-view"> Exhibition view from ISSP gallery, Latvia </h4>

        <div class="row sixth">
          <img src="../assets/divine/s-l1.png" class="img first"/>
          <img src="../assets/divine/s-l-optimized.png" class="img second"/>
          <img src="../assets/divine/s-l2-new.png" class="img third"/>
          <img src="../assets/divine/s-l3.png" class="img fourth"/>
        </div>



        <div class="row seventh">
          <p class="desc">The following  synthetic images and videos are imagined by using an Artifical Neural Network that has been trained with vast amounts of religious and sacred imagery from cultures around the world</p>
          <img src="../assets/divine/web_iod-1.png" class="img first"/>
          <img src="../assets/divine/t7.png" class="img second"/>
          <video ref="videoPlayer1" class="video1 vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
          <video ref="videoPlayer2" class="video2 vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
        </div>


        <div class="row eight">
          <img src="../assets/divine/web_kolazha.png" class="img collage"/>
          <img src="../assets/divine/web_outside-in.png" class="img outside"/>
          <img src="../assets/divine/view.png" class="img view"/>
        </div>
        <h4 class="caption second"> Installation view at Valmieras multimedia festival, Latvia </h4>

        <div class="row ninth">
          <video ref="videoPlayer3" class="video3 vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
          <video ref="videoPlayer4" class="video4 vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
          <img src="../assets/divine/id7.jpg" class="img first"/>
          <img src="../assets/divine/id8.jpg" class="img second"/>
        </div>



    </div>
  </div>
</div>
</template>

<script>
import Navigation from '@/components/navigation.vue'
import videojs from 'video.js';

export default {
  name: 'ImagesOfDivine',
  props: {
    msg: String
  },
  components: {
    Navigation
  },
  data() {
    return {
      videoOptions1: {
        autoplay: 'muted',
        controls: false,
        mute: true,
        loop: true,
        sources: [
          {
            src: "https://data-store.xyz/video/imagesOfDivine/iod-5.mov",
            type: "video/mp4",
          },
        ]
      },
      videoOptions2: {
        autoplay: 'muted',
        controls: false,
        loop: true,
        sources: [
          {
            src: "https://data-store.xyz/video/imagesOfDivine/iod-2.mov",
            type: "video/mp4",
          },
        ]
      },
      videoOptions3: {
        autoplay: 'muted',
        controls: false,
        loop: true,
        sources: [
          {
            src: "https://data-store.xyz/video/imagesOfDivine/iod-3.mov",
            type: "video/mp4",
          },
        ]
      },
      videoOptions4: {
        autoplay: 'muted',
        controls: false,
        loop: true,
        sources: [
          {
            src: "https://data-store.xyz/video/imagesOfDivine/iod-4.mov",
            type: "video/mp4",
          },
        ]
      },
      videoPlayerHandsViewOptions: {
        autoplay: 'muted',
        controls: false,
        loop: true,
        sources: [
          {
            src: "https://data-store.xyz/video/imagesOfDivine/Telefons.m4v",
            type: "video/mp4",
          },
        ]
      },
      videoPlayerHandsOptions: {
        autoplay: 'muted',
        controls: false,
        loop: true,
        sources: [
          {
            src: "https://data-store.xyz/video/imagesOfDivine/Rokas-V4-Large-web.m4v",
            type: "video/mp4",
          },
        ]
      },
    }
  },
  mounted(){
  console.log(this.videoOptions)
  this.player = videojs(this.$refs.videoPlayer1, this.videoOptions1,
      function onPlayerReady() {
          console.log('onPlayerReady', this);
      })

  this.player = videojs(this.$refs.videoPlayer2, this.videoOptions2,
      function onPlayerReady() {
          console.log('onPlayerReady', this);
      })

  this.player = videojs(this.$refs.videoPlayer3, this.videoOptions3,
      function onPlayerReady() {
          console.log('onPlayerReady', this);
      })

  this.player = videojs(this.$refs.videoPlayer4, this.videoOptions4,
      function onPlayerReady() {
          console.log('onPlayerReady', this);
      })

  this.player = videojs(this.$refs.videoPlayerHandsView, this.videoPlayerHandsViewOptions,
      function onPlayerReady() {
          console.log('onPlayerReady', this);
      })

  this.player = videojs(this.$refs.videoPlayerHands, this.videoPlayerHandsOptions,
      function onPlayerReady() {
          console.log('onPlayerReady', this);
      })


    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  p {
    @media only screen and (max-width: 600px) {
      text-align: justify !important;
    }
  }

  .container {
    width: 100vw !important;
  }
  .wrapper {
    padding-bottom: 100px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    @media only screen and (max-width: 600px) {
      margin: 0;
    }

  }

  .caption {
    width: 100%;
    margin-top: 5px;
  }


  .row {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 40px;
    width: 100%;
    margin-top: 100px;
    flex-wrap: wrap;

    @media only screen and (max-width: 600px) {
      display: flex;
      margin-top: 0;
      margin-left: 40px;
      margin-right: 40px;
    }
  }
  .row.first {
    margin-top: 0;

    .heading {
      grid-column: 7/11;
      text-align: left;
      width: 75%;
      transform: translateY(26px);

      @media only screen and (max-width: 600px) {
        width: 100%;
        transform: none;
      }
    }


  }
  .row.second {
    margin-bottom: 100px;
    align-items: center;

    @media only screen and (max-width: 600px) {
      margin-top: 10px;
      justify-content: center;
    }

    .sub-heading {
      grid-column: 4/7;
      justify-self: end;
      margin: 0;
      transform: translateY(50px);

      @media only screen and (max-width: 600px) {
        transform: translateY(-28px);
      }
    }
    .desc {
      grid-column: 3/7;
      text-align: justify;
      width: 90%;
      justify-self: center;

      @media only screen and (max-width: 600px) {
        margin-top: 10px;
        width: 100%;
      }
    }
    .cover__img {
      grid-column: 8/10;
      width: 100%;
      justify-self: center;

      @media only screen and (max-width: 600px) {
        width: 65%;
        margin-top: 50px;
      }
    }

  }
  .row.third {

    @media only screen and (max-width: 600px) {
      margin: 0;
    }

    img {
      grid-column: 1/13;
    }
  }

  .row.fourth {

    @media only screen and (max-width: 600px) {
      justify-content: center;
    }

    .img.first {
      grid-column: 3/6;

      @media only screen and (max-width: 600px) {
        width: 80%;
        margin-bottom: 30px;
      }
    }
    .img.second {
      grid-column: 6/11;
    }
  }


  .row.fifth {
    margin-top: 150px;
    align-items: center;

    @media only screen and (max-width: 600px) {
      margin-top: 50px;
      margin-left: 0;
      margin-right: 0;
      justify-content: center;
    }

    .desc {
      grid-column: 4/7;
      grid-row: 1;
      text-align: left;
      margin-top: 90px;

      display: none;
    }

    .videoHandsView {
      grid-row: 1;
      grid-column: 3/8;
    }

    .video-hands {
      grid-column: 8/11;
      grid-row: 1;
      transform: scale(0.9);

      @media only screen and (max-width: 600px) {
        width: 65%;
        transform: none;
        margin-top: 25px;
      }
    }
  }

  .row.sixth  {
    margin-top: 150px;
    grid-column-gap: 0;

    @media only screen and (max-width: 600px) {
      margin-left: 0;
      margin-right: 0;

      .img {
          border-top: 5px solid black;
          border-bottom: 5px solid black;
      }
    }


    .img.first {
      grid-column: 1/4;
    }

    .img.second {
      grid-column: 4/7;
    }

    .img.third {
      grid-column: 7/10;
    }

    .img.fourth {
      grid-column: 10/13;
    }

  }


  .row.seventh {
    margin-top: 150px;
    align-items: end;
    justify-content: center;


    .desc  {
      grid-column: 3/5;
      text-align: left;
    }

    .video1 {
      grid-column: 6/9;
      transform: scale(0.75);
    }

    .video2 {
      grid-column: 9/12;
      transform: scale(0.8) translate(-131px, 19px);
    }

    .img.first {
        grid-column: 2/4;
        transform: translateX(50px);
    }

    .img.second {
        grid-column: 4/6;
        transform: translateX(40px);
        }

    @media only screen and (max-width: 600px) {
      display: grid;
      grid-template-columns: repeat(6, 1fr);

      .desc {
        grid-row: 1;
        grid-column: 3/7;
      }

      .img.first {
        transform: none;
        margin-top: 50px;
        margin-bottom: 15px;
        grid-column: 1/5;
        grid-row: 2;
      }

      .img.second {
        transform: none;
        grid-row: 3;
        grid-column: 3/7;
      }

      .video1 {
        transform: none;
        margin-top: 15px;
        margin-bottom: 15px;
        grid-row: 4;
        grid-column: 3/7  ;
      }

      .video2 {
        transform: none;
        grid-row: 5;
        grid-column: 2/6;
        margin-top: 25px;
      }
    }
  }

  .row.eight {
    grid-row-gap: 75px;

    @media only screen and (max-width: 600px) {
      margin-top: 150px;
      grid-row-gap: 25px;
      justify-content: center;
    }


    .img.collage {
      grid-column: 6/10;
      grid-row: 1;
    }

    .img.outside {
      grid-column: 6/11;
      grid-row: 2;
      transform: translateX(50px);

      @media only screen and (max-width: 600px) {
        transform: none;
      }
    }

    .img.view {
      grid-column: 2/6;
      grid-row: 1/3;
      align-self: center;
      transform: scale(0.8);

      @media only screen and (max-width: 600px) {
        transform: none;
        width: 75%;
      }
    }
  }


  .row.ninth {
    .video3 {
      grid-column: 2/5;
      transform: scale(0.8);
    }

    .video4 {
      grid-column: 5/7;
      transform: scale(1.2) translate(-29px,47px);
    }

    .img.first {
      grid-column: 7/9;
    }

    .img.second {
      grid-column: 9/11;
      align-self: center;
      transform: scale(1.3) translateX(50px);
    }


    @media only screen and (max-width: 600px) {
      display: grid;
      grid-row-gap: 25px;
      grid-template-columns: repeat(6, 1fr);
      margin-top: 150px;

      .video3 {
        grid-row: 1;
        transform: none;
        grid-column: 3/7;
      }
      .video4 {
        transform: none;
        grid-column: 1/5;
        grid-row: 2;
      }
      .img.first {
        grid-row: 3;
        grid-column: 1/5;
      }
      .img.second {
        grid-row: 4;
        grid-column: 1/7;
        transform: none;
        margin-top: 100px;
      }
    }


  }


</style>
