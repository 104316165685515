<template>
  <div class="section">
      <video ref="videoPlayer" class="video vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
  </div>
</template>

<script>
import videojs from 'video.js';
import canAutoPlay from 'can-autoplay';

export default {
  name: 'SuggestedVR',
  props: {
    msg: String
  },
  data() {
    return {
      player: null,
      videoOptions: {
        autoplay: 'muted',
        controls: false,
        loop: true,
        muted: true,
        sources: [
          {
            src: "https://data-store.xyz/video/suggested/VR_demo-2.m4v",
            type: "video/mp4",
          },
        ]
      }
    }
  },
  methods: {},
  mounted(){
    let self = this
    canAutoPlay.video().then(function(obj) {
      if (obj.result === true) {
        // Can autoplay
      } else {
        self.videoOptions.controls = true
      }
      self.player = videojs(self.$refs.videoPlayer, self.videoOptions,
        function onPlayerReady() {
          console.log('onPlayerReady', this);
        })
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../node_modules/video.js/dist/video-js.css';

  .video {


  }
</style>
