<template>
  <div id="man-page" class="livingNoiseMain">
    <div class="container__top">
      <!--<div class="btn" @click="start()"><h4>start?</h4></div>-->
       <router-link to="/terminal">
         <div class="single-console__wrapper">
    <!--
      <div class="console__wrapper">
        <div class="terminal-line" v-for="each in state"  v-if="each.timestamp !== 'x'">
            <h4 class="timestamp"> {{ each.timestamp }} | </h4>
            <h4 class="ip"> {{ each.ip }} </h4>
        </div>
      -->
        <div v-for="each in current" :key="each.key" class="terminal-line">
          <vue-typed-js :strings="[`${each.timestamp} | ${each.ip} | ${each.pasw}`]" :typeSpeed="0.5">
            <h4 class="typing"></h4>
          </vue-typed-js>
        </div>
      </div>
    </router-link>

    </div>

    <div v-if="startVR == false" @click="launchVR" class="play-btn"> Start VR?</div>
    <AframeComp v-bind:recVal="currentPasw" v-bind:state="paswState" />
  </div>
</template>

<script>
  import AframeComp from '@/components/LivingNoiseAframe.vue'
  /* eslint-disable */
  const axios = require('axios');
  const _ = require('underscore');
   // import PageTitle from '@/components/PageTitle.vue'

  export default {
    name: 'LivinNoiseMain',
    components: { AframeComp },
    data () {
      return {
    //    serverIp: "91.205.175.216",
        serverIp: "https://living-noise.xyz",
        current: [{
          'timestamp': '',
          'ip': '',
          'pasw': ''
        }],
        paswState: [],
        ready: false,
        currentPasw: '',
        data: [
         {
           'key': 'y',
           'timestamp': "x",
           'ip': "",
           'pasw': ""
         }
        ],
        state: [
         {
           'timestamp': "x",
           'ip': ""
         }
        ],
        startVR: false
      }
    },
    methods: {
      scrollToBottom: function(){
        let el = document.querySelector(".page__wrapper")
        el.scrollTop = el.scrollHeight;
      },
      recreateState: function(){
        let app = this
        //axios.get('http://91.205.175.216:3000/state')
        axios.get(`${app.serverIp}:3000/state`)
          .then(function (response) {
            // handle success
            response.data.forEach(element => {
              let values = {
                'timestamp': element.split(',')[0],
                'ip': element.split(',')[1]
              }
              let pasw = element.split(',')[2]

              app.state.push(values)
              app.paswState.push(pasw)
            });
        //    app.scrollToBottom()
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
      },
      readLine: function() {
        let app = this
        axios.get(`${app.serverIp}:3000`)
        //axios.get('http://91.205.175.216:3000')
          .then(function (response) {
            // handle success
            let rkey = app.getRandom(0,1000000)
            let key = app.k
            const values = {
              'key': rkey,
              'timestamp': response.data.split(',')[0],
              'ip': response.data.split(',')[1],
              'pasw': response.data.split(',')[2]
            }
            const lastEl = app.data[app.data.length - 1]

            if (values['timestamp'] !== lastEl['timestamp']) { // there is a new entry
              app.getAudio()
              app.data.push(values)

              app.current.pop()
              app.current.push(values)

              app.currentPasw = values['pasw']


              console.log(JSON.stringify(app.current.pasw))

            //  setTimeout(function(){
            //    app.scrollToBottom()
            //  },100)
            }
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
      },
      getAudio: async function() {
        let app = this
        const { data } = await axios.get(`${app.serverIp}:3000/audio`, {
          responseType: 'arraybuffer',
          headers: {
              'Content-Type': 'audio/wav'
          }
        });
        const blob = new Blob([data], {
                  type: 'audio/wav'
              });
        const url = URL.createObjectURL(blob);
        const audio = new Audio(url);

        this.manipulateAudio(audio)
      },
      manipulateAudio: function(audio) {

        let ctx = new AudioContext();

        let source = ctx.createMediaElementSource(audio);
        source.connect(ctx.destination);

        audio.play()
        /*

        let ctx = new AudioContext();

        let source = ctx.createMediaElementSource(audio);

        var delay = ctx.createDelay();
        delay.delayTime.value = 0.5;

        var feedback = ctx.createGain();
        feedback.gain.value = 0.4;

        var filter = ctx.createBiquadFilter();
        filter.frequency.value = 1000;

        delay.connect(feedback);
        feedback.connect(filter);
        filter.connect(delay);

        source.connect(delay);
        source.connect(ctx.destination);
        delay.connect(ctx.destination);

        audio.play()
        */
    },
    getRandom: function(min, max) { // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    start: function() {
      this.ready = true;
      this.getDataInterval = setInterval(() => this.readLine(), 500);
    },
    launchVR: function(e) {
      console.log(e)
      this.startVR = true
      this.recreateState()
      this.getDataInterval = setInterval(() => this.readLine(), 500);
    }
  },
  destroyed(){
     clearInterval(this.getDataInterval);
     console.log("destroyed");
   },
    mounted(){
 //      this.recreateState()
  //    let getData = setInterval(() => this.readLine(), 500);
    }
  }
</script>
<style>

  #main-page .typed-cursor {
     font-size: 12px;
   }

</style>
<style lang="scss" scoped>
.element::-webkit-scrollbar { width: 0 ! important }
.element { overflow: -moz-scrollbars-none; }
.element { -ms-overflow-style: none; }
 h4 {
   color: #b5a9a9;
   font-family: Courier New,Courier,Lucida Sans Typewriter,Lucida Typewriter,monospace;
   font-size: 12px;
 }
 a {
   color: #cdcab1;
   text-decoration: none;
 }
 .btn {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   color: #cdcab1;
   border: 1px solid #cdcab1;
   cursor: pointer;
 }
 .btn h4 {
   margin: 15px;
 }
 .livingNoiseMain {
   position: relative;
   width: 1600px;
   max-width: 1920px;
   height: 800px;
   max-height: 1080px;
   margin-top: 100px;

 }
 .container__top {
   top: 20px;
   left: 15px;
   height: 20px;
   overflow: hidden;
   z-index: 1000;
   background: black;

   padding-top: 10px;
   padding-left: 10px;
   padding-bottom: 10px;

   user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */
 }
 .console__wrapper {
   margin-left: 10px;
   margin-bottom: 25px;
   margin-top: 20px;

 }
 .controls {
   width: 30%;
   color: white;
 }

 .terminal-line {
   display: flex;
 }
 .terminal-line h4 {
   margin-top: 2px;
   margin-bottom: 2px;
 }
 .typed-cursor {
   font-size: 12px;
 }
 .timestamp {
   margin-right: 10px;
 }
 .play-btn {
   width: 103px;
    padding: 10px;
    border: 1px solid white;
    background: black;
    position: absolute;
    z-index: 1;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    cursor: pointer;
 }
</style>
