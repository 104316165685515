<template>
  <div class="page">
    <Navigation class="navigation" />
    <div class="container">
      <div class="wrapper">
        <div class="text__wrapper">
          <h1 class="heading">AI Soundscapes</h1>
          <h2 class="sub-heading">Generative audio-visual installation, 2021</h2>
          <p class="desc">
  Building upon the idea about AI created autonomous content, "AI Soundscapes" is a 20 hour realtime audio-visual installation. By using the AI generated images based on authors' Instagram feed and their classification that were part of the "Suggested" project, an algorithm is used to find audio samples that match these classifications. The resulting library of more than 1000 audio samples is played back randomly, layering multiple tracks on top of each other. In the visual part of the project the used AI images are morphed with each other and juxtaposed with their corresponding depth maps.
          </p>
        </div>
        <video ref="videoPlayerTd" class="videoTd vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
        <video ref="videoPlayer" class="video vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>

      </div>
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/navigation.vue'
import videojs from 'video.js';

export default {
  name: 'AISoundscapes',
  props: {
    msg: String
  },
  components: {
    Navigation
  },
  data() {
    return {
      videoOptionsTd: {
        autoplay: false,
        controls: false,
        loop: true,
        sources: [
          {
            src: "https://data-store.xyz/video/aiSoundscapes/td.m4v",
            type: "video/mp4",
          },
        ]
      },
      videoOptions: {
        controls: true,
        autoplay: false,
        loop: true,
        sources: [
          {
            src: "https://data-store.xyz/video/aiSoundscapes/ai_soundscapes.m4v",
            type: "video/mp4",
          },
        ]
      }
    }
  },
  methods: {
    playVideo: function(){
      alert(1)
    }
  },
  mounted(){
    let self = this
    console.log(this.videoOptions)
    this.playerTd = videojs(this.$refs.videoPlayerTd, this.videoOptionsTd,
        function onPlayerReady() {
            console.log('onPlayerReady', this);
        })
    this.player = videojs(this.$refs.videoPlayer, this.videoOptions,
        function onPlayerReady() {
            console.log('onPlayerReady', this);
        })
    this.player.bigPlayButton.on('click', function(){
      self.playerTd.play()
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../node_modules/video.js/dist/video-js.css';

  .wrapper {
    grid-template-rows: auto auto auto;
    margin-top: 50px;
  }
  .text__wrapper {
    grid-column: 2 / 6;
    grid-row: 1;
    text-align: right;
  }
  .desc {
    margin-top: 50px;
    text-align: justify;
    transform: translateY(20px);
  }
  .videoTd {
    grid-column: 7/13;
    grid-row: 2;
    transform: translate(-50px, -70px);
  }
  .video {
    grid-column: 1 / 11;
    grid-row: 3;
    width: 100%;
    transform: translateY(-40px);
  }
</style>
