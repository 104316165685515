<template>
  <div class="section">
        <div class="img__container">
            <img class="img" v-for="img in imgs" :src="img.img"  :key="img.id" data-toggle="tooltip" />
        </div>
  </div>
</template>

<script>
/* eslint-disable */
//import PageTitle from '@/components/PageTitle.vue'
const axios = require('axios');

export default {
  name: 'SuggestedGenerated',
  components: {
      //  PageTitle, Stage, Footer, NavBar
  },
  data () {
    return {
      imgSrc: "",
      imgs: [
        {id: 1, img: '/img/0451.c2529d07.png'},
        {id: 2, img: '/img/0309.422b521e.png'},
        {id: 3, img: '/img/0646.cfc651d8.png'},
        {id: 4, img: '/img/0641.844a630b.png'},
        {id: 5, img: '/img/0188.d1d50035.png'},
        {id: 6, img: '/img/0378.90ed5398.png'},
        {id: 7, img: '/img/0459.b3097f3a.png'},
        {id: 8, img: '/img/0890.a61a0077.png'},
        {id: 9, img: '/img/0890.a61a0077.png'},
        {id: 10, img: '/img/0890.a61a0077.png'},
        {id: 11, img: '/img/0890.a61a0077.png'},
        {id: 12, img: '/img/0890.a61a0077.png'},
      ],
      animSpeed: [2000,2000],
      animate: true
    }
  },
  methods: {
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    },
   padToFour: function(number){
     if (number<=9999) { number = ("000"+number).slice(-4); }
     return number;
   },
   prepImgs: function(){
     let app = this
     for (let i = 0; i < 11; i++) {
       let ran = this.getRandom(0,1000)
       let img = this.padToFour(ran)
       let imgSrc = require(`../assets/gen_imgs/${img}.png`)
       app.imgs[i]['img'] = imgSrc
     }
    this.controlLoop()
   },
   controlLoop: async function() {
    let pos = this.getRandom(0, 11)
    this.changeImg(pos)
    let rsleep = this.getRandom(0,1000)
    await this.sleep(rsleep)
    this.controlLoop()

   },
   changeImg: function(pos){
     let ran = this.getRandom(0,1000)
     let img = this.padToFour(ran)
     let imgSrc = require(`../assets/gen_imgs/${img}.png`)
     this.imgs[pos]['img'] = imgSrc
   },
   getRandom: function(min, max) { // min and max included
     return Math.floor(Math.random() * (max - min + 1) + min);
   }
  },
  computed: {
  },
  mounted(){
    this.prepImgs()
  }

}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .suggestedGenerated {
    width: 1920px !important;
  }
  .section {
    margin: 0 auto;
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .line {
    width: 100%;
    height: 2px;
    background: #f9dd5d;
    position: absolute;
    display: none;
  }
  .t {
    display: flex;
    width: 500px;
    position: relative;
  }
  .t1 {
    margin-right: 45px;
    width: 400px;
    height: 400px;
  }
  .t2 {
    width: 500px;
    height: 500px;
  }
  h3 {
    font-family: 'Courier New', monospace;
    color: black;

  }
  .wtf {
    display: none;
  }
  .img__container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;

  }
  .img__container img {
    width: 16.6%;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .2s ease;
  }
    .fade-enter, .fade-leave-to
    /* .component-fade-leave-active below version 2.1.8 */ {
      opacity: 0;
    }
  .desc__wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: left;
    display: flex;
    align-items: center;
  }
  /* SCORE ITEMS  */
  .item {
    padding: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 2px;
    margin-bottom: 2px;
    background: #f9dd5db3;
    width: 250px;
  }
  .item h3 {
    display: inline-block;
    margin: 0;
    font-size: 0.8em;

  }
  .item .title {
    padding: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .item .score {
    padding: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .img__container img {

  }

</style>
