<template>
  <div class="page">
    <Navigation class="navigation" />

    <div class="bio-container">
      <div class="wrapper">

      <!--  <img src="../assets/landing_img.jpg" class="img"/> -->
      <div class="education__wrapper">
        <h4 class="subtitle">Education</h4>
        <div class="item" v-for='(val, key) in education' :key='key'>
          <h4 class="years">{{val.yearFrom}} - {{val.yearTo}}</h4>
          <h4 class="degree">{{val.degree}} </h4>
          <h4 class="uni">{{val.uni}} </h4>
          <h4 class="country">{{val.country}}</h4>
        </div>
        <h4 class="contact">contact: hello@jurgispeters.xyz</h4>
        <div class="statement">
          <router-link to="/statement">biography</router-link>

       </div>
       </div>
       <div class="exhibitions__wrapper">
         <h4 class="subtitle"> Exhibitions / Events </h4>
         <div class="item__container">
          <div class="item" v-for='(val, key) in exhibitions' :key='key'>
            <h4 class="date">{{val.date}}</h4>
            <h4 class="title">{{val.title}}, <br/><span>{{val. location}}</span> </h4>
          </div>
        </div>

       </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/navigation.vue'


export default {
  name: 'Bio',
  props: {
    msg: String
  },
  components: {
    Navigation
  },
  data () {
    return {
      education: [
        {
          yearFrom: '09/2023',
          yearTo: '06/2027',
          degree: 'PhD in Convergence of Humans and Machines',
          uni: 'Tampere University',
          country: 'Finland'
        },
        {
          yearFrom: '09/2019',
          yearTo: '06/2021',
          degree: 'MA in Audiovisual Arts',
          uni: 'Art Academy of Latvia',
          country: 'Latvia'
        },
        {
          yearFrom: '09/2017',
          yearTo: '06/2018',
          degree: 'MSc in Cyber Security',
          uni: 'University of Birmingham',
          country: 'United Kingdom'
        },
        {
          yearFrom: '09/2013',
          yearTo: '06/2016',
          degree: 'BSc Digital Media Technology',
          uni: 'Birmingham City University',
          country: 'United Kingdom'
        },
      ],
      exhibitions: [
        {
          date: '09/2023',
          title: 'Group show "Crypto, Art and Climate"',
          location: 'National Library of Latvia'
        },
        {
          date: '07/2023',
          title: 'Solo show "Synthetic Presence"',
          location: 'Gallery ISSP, Riga, Latvia'
        },
        {
          date: '03/2023',
          title: 'Travelling exhibition "Art on Wheels", visiting schools across Latvia and Norway',
          location: 'Latvia, Norway'
        },
        {
          date: '03/2023',
          title: 'Multimedia art festival "Valmiera"',
          location: 'City of Valmiera, Latvia'
        },
        {
          date: '10/2022',
          title: 'Group exhibition "Meta_Contra_Verse"',
          location: 'Gallery GESTE, Paris, France'
        },
        {
          date: '09/2022',
          title: 'Group exhibition as part of RIXC organized art and science festival "Splintered Realities"',
          location: 'Gallery KIM?, Riga, Latvia'
        },
        {
          date: '07/2022',
          title: 'Visuals and sceongraphy for contemporary chamber music concert "Possiblities of Life"',
          location: 'Cultural venue TU JAU ZINI KUR, Riga, Latvia'
        },
        {
          date: '11/2021',
          title: 'RIXC organized AR Group exhibition "Experimental Ecologies"',
          location: 'Garden by LNMM, Riga, Latvia'
        },
        {
          date: '11/2021',
          title: 'AI festival "When Machines Dream Future", presenting work from RIXC residency',
          location: 'DEUTSCHES HYGIENE MUSEUM, Dresden, Germany'
        },
        {
          date: '10/2021',
          title: '20h generative audio visual installation for Tirkultura "Unexpected Sources" audio gallery',
          location: 'Art gallery ALMA, Riga, Latvia'
        },
        {
          date: '09/2021',
          title: 'Pop-up group exhibition "Suggeste & etc."',
          location: 'In the premises of an ex-internet shop, Riga, Latvia'
        },
        {
          date: '08/2021',
          title: 'Nightly light installation "Ignis Faatus" for the festival Sansusi',
          location: 'Suseja, Latvia'
        },
        {
          date: '06/2021',
          title: 'Residency "Connected/Nest" organized by Goethe-Institut Riga',
          location: 'Gallery RIXC, Riga, Latvia'
        },
        {
          date: '10/2020',
          title: 'Pop-up group exhibition "POHAS"',
          location: 'Billiard Club BAMS, Riga, Latvia'
        },
        {
          date: '09/2019',
          title: 'Group exhibition "Academia"',
          location: 'Exhibition hall ARSENALS, Riga, Latvia'
        },
        {
          date: '08/2019',
          title: 'Pop-up group exhibition "7ka"',
          location: 'Temporary use space in Riga, Latvia'
        },
        {
          date: '07/2019',
          title: 'Experimental audio visual performance "Sound Kitchen"',
          location: 'Cultural venue TU JAU ZINI KUR, Riga, Latvia'
        },
        {
          date: '04/2019',
          title: 'Experimental audio visual performance "Water Spiral"',
          location: 'Cultural venue TU JAU ZINI KUR, Riga, Latvia'
        },
        {
          date: '02/2019',
          title: 'Participatory contemporary art workshop "Drink and Make Contemporary Art"',
          location: 'Gallery LOW, Riga, Latvia'
        },
        {
          date: '08/2018',
          title: 'Group exhibiton "HOME"',
          location: 'OLD PRINT WORKS, Birmingham, United Kingdom'
        },

      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  h4 {
      font-weight: normal;
      font-size: 0.8em;
      margin: 2px;
    }
  .page {
    height: 100vh;
    background: black;
    display: flex;
    align-items: center;
  }
  .wrapper {
    display: block;
    display: flex;
    justify-content: center;
    margin-top: 100px;
  }
  .education__wrapper {
    align-self: center;
    margin-right: 50px;

    .subtitle {
      font-size: 1.2em;
      text-align: left;
      color:white;
    }

    .item {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 20px;

      h4 {
        text-align: left;
      }
      .years {

      }
      .degree {
        font-size: 1em;

      }
      .uni {
        font-size: 1em;
      }
    }
    .contact {
      text-align: left;
      color: white;
    }

    .statement {
      text-align: left;

      a {
        text-decoration: none;
        color: white;
        font-size: 0.8em;
        margin-left: 3px;
      }
    }

  }
  .exhibitions__wrapper {
    width: 60%;

    @media only screen and (max-width: 1550px) {
      width: 70%;
    }

    .subtitle {
      text-align: left;
      font-size: 1.2em;
      color: white;
      margin-bottom: 5px;
    }
    .item__container {
      display: flex;
      flex-wrap: wrap;
    }
    .item {
      display: flex;
      flex-wrap: wrap;
      padding-top: 4px;
      margin-bottom:4px;
      padding-top: 4px;
      padding-bottom: 4px;
      margin-right: 30px;
      width: 30%;

      .date {
        width: 100%;
        text-align: left;
        font-size: 1em;
      }
      .title {
        width: 100%;
        text-align: left;
        font-size: 0.8em;

        span {
          font-style: italic;

        }
      }
    }
  }
</style>
