<template>
  <div class="page">
    <!--<Navigation class="navigation" />-->
    <div class="container">
    <Navigation class="navigation" />

    <div class="wrapper">
        <!--<img src="../assets/divine/011.png" class="head img"/> -->
        <div class="title-row">
          <h1 class="heading">New Trajectories</h1>
          <h2 class="sub-heading">VR, 2023</h2>
        </div>
        <p class="desc">
          VR artwork was created for the project “Art on Wheels” in which multiple artworks will travel around schools both in Latvia and Norway introducing pupils to contemporary art forms. <br /><br/>
        “New Trajectories” alternates between the solid presence of physical reality and imaginary future scenarios, where beings created by artificial intelligence (AI) play an increasingly important role. The starting point for the work was a series of 3D scans created during two workshops in Jūrmala State Gymnasium. These scans capture the youth's protests for a cause that seemed to them relevant at the given time. Developing the idea of the protest further and looking at it in the context of the future, we enter a speculative sphere where AI beings have become conscious and, naturally, they begin to demand their rights and freedom from their masters. What might this new reality, which is as much virtual as physical, look and sound like, and how would the growing role of AI in our common co-existence materialise? In the VR experience, the viewer begins their journey in a scanned 3D environment that resembles the physical environment in which the work itself resides. After a short walk, the viewer is given the opportunity to fly through the physical sphere and, as they ascend, reach an in-between space where point clouds of scanned protesting youth become individual star systems and cosmic objects. Continuing upwards, the viewer finally enters a futuristic fusion of worlds where dynamic visions of the future are created as AI beings appear and disappear. The 360-degree environments used in the project are generated via AI. The visual experience is complemented by a soundtrack, created by layering freely available sounds found on the web, archives of field recordings and the artist's own sounds.

        </p>
        <img src="../assets/newTrajectories/DSC_0608.png" class="img first"/>

        <div class="row first">
          <video ref="videoPlayer1" class="video1 vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
          <h4 class="caption"> VR screen recording </h4>
        </div>

        <video ref="videoPlayer2" class="video2 vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
        <video ref="videoPlayer3" class="video3 vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
        <video ref="videoPlayer4" class="video4 vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>

        <div class="row last">
          <img src="../assets/newTrajectories/00051.png" class="img second"/>
          <h4 class="caption"> AI generated environment </h4>

        </div>
    </div>
  </div>
</div>
</template>

<script>
import Navigation from '@/components/navigation.vue'
import videojs from 'video.js';

export default {
  name: 'NewTrajectories',
  props: {
    msg: String
  },
  components: {
    Navigation
  },
  data() {
    return {
      videoOptions1: {
        controls: true,
        mute: false,
        loop: false,
        sources: [
          {
            src: "https://data-store.xyz/video/newTrajectories/Vr-Screenrecord-2.m4v",
            type: "video/mp4",
          },
        ]
      },
      videoOptions2: {
        autoplay: 'muted',
        controls: false,
        loop: true,
        sources: [
          {
            src: "https://data-store.xyz/video/newTrajectories/Tv-Part-2.m4v",
            type: "video/mp4",
          },
        ]
      },
      videoOptions3: {
        autoplay: 'muted',
        controls: false,
        loop: true,
        sources: [
          {
            src: "https://data-store.xyz/video/newTrajectories/Tv-Part-1.m4v",
            type: "video/mp4",
          },
        ]
      },
      videoOptions4: {
        autoplay: 'muted',
        controls: false,
        loop: true,
        sources: [
          {
            src: "https://data-store.xyz/video/newTrajectories/TV-Part-3.m4v",
            type: "video/mp4",
          },
        ]
      },
    }
  },
  mounted(){
  console.log(this.videoOptions)
  this.player = videojs(this.$refs.videoPlayer1, this.videoOptions1,
      function onPlayerReady() {
          console.log('onPlayerReady', this);
      })

  this.player = videojs(this.$refs.videoPlayer2, this.videoOptions2,
      function onPlayerReady() {
          console.log('onPlayerReady', this);
      })

  this.player = videojs(this.$refs.videoPlayer3, this.videoOptions3,
      function onPlayerReady() {
          console.log('onPlayerReady', this);
      })

  this.player = videojs(this.$refs.videoPlayer4, this.videoOptions4,
      function onPlayerReady() {
          console.log('onPlayerReady', this);
      })
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .container {
    width: 100vw !important;
  }
  .wrapper {
    padding-bottom: 100px;
    width: 100%;
    row-gap: 80px;

    @media only screen and (max-width: 600px) {
      margin: 0;
      row-gap: 50px;
    }
  }

  .title-row {
    grid-row: 1;

    text-align: right;
    grid-column: 3/7;
    transform: translate(95px,107px);

    @media only screen and (max-width: 600px) {
      order: 1;
      width: 50%;
      margin-left: auto;
      transform: translateY(20px);
      margin-right: 40px;
      margin-top: 80px;
    }
  }

  .desc {
    grid-row: 2;

    grid-column: 3/7;
    align-self: center;
    text-align: justify;
    transform: translateX(95px);

    @media only screen and (max-width: 600px) {
      order: 3;
      transform: none;
      margin-left: 80px;
    }
  }

  .img.first {
    grid-column: 8/11;
    grid-row: 2;

    @media only screen and (max-width: 600px) {
      order: 2;
      width: 50%;
    }
  }

  .row.first {
    grid-column: 3/11;
    grid-row: 3;
    transform: scale(0.9);

    @media only screen and (max-width: 600px) {
      order: 5;
      width: 75%;
      margin: 0 auto;
    }
  }
  .video2 {
    grid-column: 5/13;
    grid-row: 4;

    @media only screen and (max-width: 600px) {
      order: 4;
      transform: none;
    }
  }
  .video3 {
    grid-column: 2/8;
    grid-row: 5;

    @media only screen and (max-width: 600px) {
      order: 6;
    }

  }
  .video4 {
    grid-row: 6;

    grid-column: 3/11;
    transform: scale(0.9);

    @media only screen and (max-width: 600px) {
      order: 7;
      width: 75%;
      margin-left: auto;
      transform: none;
    }

  }
  .row.last {
    grid-column: 1/13;
    grid-row: 7;

    @media only screen and (max-width: 600px) {
      order: 8;
    }

  }








</style>
