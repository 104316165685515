<template>
  <div class="page">
    <Navigation class="navigation" />
    <div class="container">

      <div class="wrapper">
        <div class="text__wrapper">
          <div class="heading__wrapper">
            <h1 class="heading">Connected / Nest</h1>
            <h2 class="sub-heading">RIXC residency, 2021</h2>
          </div>
          <p class="desc">
            During the residency "Connected / Nest" at the RIXC gallery, a humanoid robot was `taught` to aid in bringing new life into existence. A special egg incubator was built for this project that was then connected to the robot called Naomi. By adjusting the temperature and humidity of the incubator as well as turning the eggs frequently, Naomi was successful in nurturing chiks. With this non-trival act we started the discussion about how the AI could be viewed as guardians and helpers rather than competitors to us humans.
          </p>
        </div>
        <img src="../assets/connectednest/1.jpg" class="first img"/>
        <img src="../assets/connectednest/2.jpg" class="second img"/>
        <img src="../assets/connectednest/3.jpg" class="third img"/>
        <img src="../assets/connectednest/4.jpg" class="fourth img"/>
        <img src="../assets/connectednest/5.jpg" class="fifth img"/>
        <img src="../assets/connectednest/chick.png" class="sixth img"/>
        <img src="../assets/connectednest/qr.png" class="seventh img"/>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/navigation.vue'

export default {
  name: 'ConnectedNest',
  props: {
    msg: String
  },
  components: {
    Navigation
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .wrapper {
    grid-template-rows: auto auto auto auto auto;
  }
  .text__wrapper {
    grid-column: 2/8;
    grid-row: 1;
    margin-bottom: 25px;
  }
  .heading__wrapper {
    grid-column: 3 / 7;
    grid-row: 1;
    text-align: right;
    align-self: end;
    margin-right: 75px;
  }
  .desc {
    grid-column: 3 / 8;
    grid-row: 2;
    text-align: right;
  }
  .first.img {
    grid-column: 8/11;
    grid-row: 1/2;
  }
  .second.img {
    grid-column: 3/7;
    grid-row: 2;
  }
  .third.img {
    grid-column: 7/13;
    align-self: flex-end;
    grid-row: 2;
  }
  .fourth.img {
    grid-column: 1/7;
    grid-row: 3;
  }
  .fifth.img {
    grid-column: 7/11;
    grid-row: 3;
  }
  .sixth.img {
    grid-column: 2/7;
    grid-row: 4;
    transform: translateY(-100px);
  }
  .seventh.img {
    grid-column: 7/9;
    transform: translateY(22px);
    grid-row: 4;
  }
</style>
