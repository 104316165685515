<template>
  <div class="page">
    <Navigation class="navigation" />
    <div class="suggested container">
      <div class="wrapper">
        <h1 class="heading">Suggested</h1>
        <div class="desc__wrapper">
          <h2 class="sub-heading">Mixed media, 2021</h2>
          <p class="desc">
            For my Master’s. project “Suggested” I have researched the implications of the so-called information bubbles, the suggestion algorithms that enable them and the personalized content that is created as a result. At the moment AI is used thoroughly both for profiling each user and for finding relevant content. Taking in consideration the rapid development of various AI fields, it seems that in the near future AI will be used not only for finding the relevant content but also for creating it. With “Suggested” I have attempted to speculate about this new AI created personalized content and its consequences. <br/><br/>
            Over 1000 Instagram suggested images  were collected from my Instagram account for this project. These images were then analyzed and labeled using Google Cloud Vision algorithms as a reference to the individual profiling carried out by suggestion algorithms.
          </p>
        </div>
      </div>

      <SuggestedImgAnalysis class="suggestedImgAnalysis" />

      <h4 class="caption">Dominant colours and classifications for all the collected Instagram images  </h4>

      <SuggestedImgAnimation class="suggestedImgAnimation" />

      <h4 class="caption">Instagram image analysis  </h4>

      <SuggestedBirth class="suggestedBirth" />
      <SuggestedGenerated class="suggestedGenerated" />
      <h4 class="caption"> AI generated image feed  </h4>
      <SuggestedVR class="suggestedVR" />
      <h4 class="caption"> Screenrecording of VR experience </h4>

    </div>
  </div>
</template>

<script>
import Navigation from '@/components/navigation.vue'
import SuggestedImgAnalysis from '@/components/SuggestedImgAnalysis.vue'
import SuggestedImgAnimation from '@/components/SuggestedImgAnimation.vue'
import SuggestedBirth from '@/components/SuggestedBirth.vue'
import SuggestedGenerated from '@/components/SuggestedGenerated.vue'
import SuggestedVR from '@/components/SuggestedVR.vue'



export default {
  name: 'Suggested',
  components: {
    Navigation,
    SuggestedImgAnalysis,
    SuggestedImgAnimation,
    SuggestedBirth,
    SuggestedGenerated,
    SuggestedVR
  }
}
</script>
<style scoped lang="scss">
  .suggested {
    width: 100vw;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    overflow: hidden;
  }
  .wrapper {
    grid-template-rows: auto auto;
    margin-bottom: 100px !important;
  }
  .heading {
    grid-column: 3 / 6;
    grid-row: 1;
    text-align: left;
  }
  .caption {
    width: 100%;
    font-weight: normal;
    margin-bottom: 75px;
    font-size: 0.8em;
    font-style: italic;
  }
  .desc__wrapper {
    grid-column: 3 / 7;
    grid-row: 2;
    text-align: left;
  }

  .section {
    width: 1200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px !important;
  }

</style>
