<template>

  <div class="landing-container">
    <div class="wrapper">
      <div class="nav">
        <li class="item"><router-link to="/memories"> Memories_From_A_Distant_Future</router-link></li>
        <li class="item"><router-link to="/synthetic"> Encounters_At_The_Other_Side</router-link></li>
        <li class="item"> <router-link to="/imagesofdivine"> Images_of_The_Divine</router-link> </li>
        <li class="item"> <router-link to="/newtrajectories"> New_Trajectories</router-link> </li>
        <li class="item"> <router-link to="/alternative"> Alternative_Realities</router-link> </li>
        <li class="item"> <router-link to="/familiar"> So_Familiar_Yet_So_Strange</router-link> </li>
        <li class="item"> <router-link to="/aisoundscapes"> AI_Soundscapes</router-link> </li>
        <li class="item"><router-link to="/suggested"> Suggested</router-link> </li>
        <li class="item"><router-link to="/ignisfaatus"> Ignis_Faatus</router-link></li>
        <li class="item"><router-link to="/connectednest"> Connected_/_Nest</router-link></li>
      <!--  <li class="item"><router-link to="/livingnoise"> Living_Noise</router-link></li> -->
        <li class="item"><router-link to="/roomwithaview">Room_With_a_View </router-link></li>
      </div>


<p class="img-ascii"><pre>::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::^^~~~!!!!!!!!!!!777777777???????7??77777!!~!!!!~~~~!~~~~~~~~~~~~^::::::::::::::::::::::::::::
::::::^~!!!77!77777???????????JJJYYYYYYYYJYJ?77!777!!!7!!!!!!!!~!!!!!!!!~^::::::::::::::::::::::::::
::::^~~~~!!!!!!!777777777????????JJJYJJJJJJ???77!77!77!!!!!!!!!~~!~!!~~!!!~:::::::::::::::::::::::::
::::~~!!!!!!!!!!777777777777??????JJJ?J??77?7!77!!!!!!!!!!!!!~~~!!!!!~~~~!!~^:::::::::::::::::::::::
:::^~!!!!!!!!!!!7!7777777777777777777?777?7??7!!7!!!!~~~~^^~~~!!!!!!~~~~~~~!!^::::::::::::::::::::::
::^~~!!!!!!!!!7!7777!!!!!!7!777777!?7!!7!!!~!!~!^^^^^^^^^~~!~~~~^~~!!~~~~~~~!~::::::::::::::::::::::
:^~~!!!!~!~!~!77!7!7!!!!~!7!!!!!!!7!!!!!~!!!!!~~^^^^^~~!!77!~~~~~~!~!~~~~~~~~!~:::::::::::::::::::::
^~~!!~~!~!!~~!!!~!!!!~!!!!!~~!!!!!!~!!~!!!!~^~~~~^!!!~~~!~^~~~~~~!!!~!^~!!!~~~!^::::::::::::::::::::
~~~~~~!~~~~!!~~~~~!!7!~!!!!~!7!7!~!7777!!!!~~~~~~~!77~~~!!~!~^~~!!!!!7!!!!!~~~!~::::::::::::::::::::
~~~~~~~!!~~!!~~^~~^!7!!!!!!~!7!~!~~!!!~~!~~!~!!!~!!!~^~!~~~~^~!~!!!!!!!7~~~~~~~!^:::::::::::::::::::
!~~~~~~~~~~!!7!!!!!!~!!~~~!!!~!!~!!!!~!7!!!~~~!!!77!~^!~!!!~^^~~!7~~!!!!~~~~~^~~~:::::::::::::::::::
!~!~~~~~~!~~~!~!~!!~!~!!~~!!!!!!!!77!!!7!~~!!77!~!~~~~~~!7!!!~!!!~!~!!!~^~~!~^~~!^::::::::::::::::::
~~~~~~~~~~!~~~!!!~!!!~!!!77!!!!!~!7777!7!!!!!77777!!!~!!!7777777!!!!!!!~~!!!~~~!!!::::::::::::::::::
~~~~!~!!!~!!~!!!!!~!!!!!7!!~!!7777??777!!!7?J77777!~~!77777!!!7!!!7!!!!!!7!!!!!!!7^:::::::::::::::::
~~~!!!!!!!!!!~~!!7!!!7!!!!!!!!!!!7??!~!~!7777??????JJ????J!!77~~!!!!~!77777777!!!!~:::::::::::::::::
~~~!!!!!~!!7!!!!!!7!!!!~!777777???J?77??77777!77?JYYJJJJ?7!!7!!~!!!!7?J??77?7!!!~!~:::::::::::::::::
^~~!!!!!!~~~!!~!!!~!7!!~!7777777???7?JJJ?JJJJJ??JJJ??JY??77?!777!!!??J????J?77!!7!~:::::::::::::::::
!!!~~~!!!!!~!!~~~~~!!!7777!777??777JJYYJ??7??77JYJ77?J?J??YJJ??7!!7777??7??7!!7???~:::::::::::::::::
!!!~~~~~~!!~~7!~~!!!7!!77777???JJJJJYJ??JJ??77?J??Y55YY5YY5JJ?7!!~!77?JJ??77!?JYY?^:::::::::::::::::
~!!!!!!~!!!!~~!7!!77!!!!7????JJJ?JJJ??JJYJ?J?YYJ7?JJJJJYY5J??77!~~!!7JJ???77?JJJY!^:::::::::::::::::
^7J7!!!~!!!!!!!!7?7!!!7777?77??JJJJ?7JYYY??JJ?!!!??7?J?JYY??7?7!!!!!!7!!!777777?7~^:::::::::::::::::
:~?7!!~~!~!7~!~~~!~~!!!777777?JJJYJY5YJ?JJJJ7!??JJJJJJ5555YYJ?77!7!7~~!7!!!7!!!!~^::::::::::::::::::
:~7?~~!!7!!7!!~!~!~!!!!!777???7??JYYYJ?JJ77!77777777!?J??JJJ??Y5J!77~~77!!!!~~!~~^::::::::::::::::::
::!??~~~!77!!!7!7!77!!!!!!!7!77??JJ?????7?????77???JY5JYYJJJJ77??77!!~~~~~~~^~~~~!^:::::::::::::::::
::~7?7!!!!7!7!77?!77!!7777777777?JJ??J?JJ?7!!77?J?J???JJJ55J??????777~^^~^^~!~^~~!~:::::::::::::::::
:::!7?!!~~7777!~!~!!7777!7777777???7?J?777??7J?777??????J???JJ??J??7~~~~~~~~~~~~!!~:::::::::::::::::
:::^7?7!!~~!7777!!!!!777777777?777?J?7??J???????J??JJJJJYYJJ?JJJ?7?7!!~^~~!~~!!~~!!:::::::::::::::::
::::^77?!~~~7?777777!77!!777777?7??7???????7?J????J77??JYJJJJJJYJJJ?!7!!~~!!~~~~~7!:::::::::::::::::
:::::^77?7!~!7?777?777?77777??77777??JJ?????7777??77?JJJ??YJJJJJ?JJ???7!!77~~!~~77^:::::::::::::::::
::::::~7?J?7!!!77?7777??777??77????JJ?7???77?JJJ?!7?JJJYYYJYJJYJYYJJJ77777!!~!~!7!::::::::::::::::::
:::::::^~7JJ?77?77?77?7!???7????7???!7???77?7!7??J????J??7J?7??7???J777~~^^^^!!77^::::::::::::::::::
::::::::::^7?77777??7777777???7??77?J?J???77?J???7777?????7???77!!!!!!^^^^~^^~!7~:::::::::::::::::::
:::::::::::^!!7777777???7?7?7??7?77??77??7?777777??JJ?J!!7!7777!!!~!7!~~~^^^^~!~::::::::::::::::::::
::::::::::::^7!7777!77??7777?77??77??7???77!7??777??!77??7777!!7?7!!~!!~~~^^~~~:::::::::::::::::::::
:::::::::::::~7!??J?7!!!7?777?J???????7777?777?77J7!7???7??7!!77!!!!77!!~~~^^~~:::::::::::::::::::::
:::::::::::::^!7??7!77!!!!7?777777777??????77????77?J7!???777!!7??!!7777777!~~^:::::::::::::::::::::
::::::::::::::^77?77!!77?7!777??7??777777777???7????7?JJ?????77777??777777?7~~::::::::::::::::::::::
:::::::::::::::~7??7?7!!!777!77?J???777???77?77???7??JJ7??7J?7!!?777?77!!!7!~^::::::::::::::::::::::
::::::::::::::::77???77!7!!777777777??????????JJ?7?J7?7??????77~~!7!!7!77!!~~^::::::::::::::::::::::
::::::::::::::::~7?JJ77!!?!!!7777???777?J?7?????77?7??7J?????7!!!!7?77777!~~^:::::::::::::::::::::::
:::::::::::::::::777?J??7!7!777777??777?????J???7??777J7??????7!!77777!!~~~~^:::::::::::::::::::::::
:::::::::::::::::!?7!???J?777!!7777777?777??7???7?7!7??7??7!?7!~~!~^~~~~~~~^::::::::::::::::::::::::
:::::::::::::::::~?7!77?JJ???77?7777??7??J??7?7?77?????7?7!777!~~~!^^~~!~~~:::::::::::::::::::::::::
:::::::::::::::::^777!7777??JJJ???7??77????7?77??777!???7!!77!!!~~!~!!!~~~^:::::::::::::::::::::::::
::::::::::::::::::7?777!7!777?JJJJJJJ??77????7??!77??777!77?7!7!!~~!!!!~~~^:::::::::::::::::::::::::
::::::::::::::::::!?7!!77!!!!!!7?JJJJJJJJ?J??J?7?7?7777777!!!77!~~~^~~!!!~::::::::::::::::::::::::::
::::::::::::::::::~?77!777!!!7!77???J?YJYYYYYJJJ?J???77??7777777!~~~~!!!~:::::::::::::::::::::::::::
:::::::::::::::::::??7!!7!777!!77????????JYYYYYYY5YYYJJJ????777?7!!!7!~^::::::::::::::::::::::::::::
:::::::::::::::::::!J77!77!!!777777??7??JJJ??????JJJJ????JJJ???J?JJJ?7~~~^^:::::::::::::::::::::::::
:::::::::::::::::::^??7!!!!!!!77!7777?77??7??7?77777?7!!!!!777!7!777?J???????77!!~^^::::::::::::::::
:::::::::::::::::::^7?7!!!!!!!!7777777?7777??777777!!!~~~~~^~~!!!~~!77777?????JJJJJ???!!~~~~^^^:::::
::::::::::::::::::::7?!!!!!!777!!7!777??7?????7777!7!!!~~!~!~~~~~!!!!!7777777??????J?JJJJJJJJJJ?777!
::::::::::::::::::^~??7!!!777!777777???7J?7777?77777!!!7!!!!~~~~~!!~!!!!!!7777777?7?????????JJJJJJJY
::::::::::::::::::~7J??777777777???7??7????7?7???7?!!777!7!~~~~~~~~~~!!!7777777777?7???????????JJJ?J
::::::::::::::::~777?J?7777777??77?7J?????7?7??7?7?77777!!!!!~!!!~~~~!!777777?77???7????7???J???JJJJ
:::::::::::::^~!777?JJ?7??777????????JJ???7?7???7??77!77!77~~!~!!!~!!!!777??7777??????????J?J???J?JJ
:::::::::::^~!!7777???????????7?JJ???JJ?????????7?7777!!77!!!!!~!!~!!~777?77?7????J?J?????JJ??????JJ
:::::::::^!!!7!777??J??J??????JJ???????????????????7?777!!!~~!!~~!~!!777???JJJJJJJJJJJJJJJJ??7?777??
:::::::^~~~~~!!~!!!!!!!77!777!!!!!!!!!!!!!!!!!!!!!!~~!!~~!~~~~~~~~~~~!!!!!!7777777777777!!!!!~!!~!!!
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
</pre></p>

      <div class="text-part">
        <h1 class="heading">Jurgis Peters</h1>
        <p class="desc"> is a new media artist and researcher mainly interested in exploring the impact of various phenomena caused by advances in technology. As such, one of the main focuses for work is Artificial Intelligence both as a medium and as a conceptual exploration. He is currently pursuing a PhD degree in Convergence of Human and Machine in Tampere Univeristy and has graduated from Art Academy of Latvia with an MA in audiovisual arts. Having had a strong background in technology, Jurgis often uses self written algorithms and tools for artistic expression. </p>
        <div class="about">
          <router-link to="/about">more_info</router-link>
        </div>
      </div>
      </div>
  </div>
</template>

<script>

export default {
  name: 'Landing',
  props: {
    msg: String
  }
}
</script>
<style>
  .main {
      background: #000;
  }
</style>
<style scoped lang="scss">
  .landing-container {
    height: 100vh;
    width: 1200px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    overflow: hidden;
  }
  .wrapper {
    margin-top: 75px;
    grid-template-rows: 400px 300px;
    align-self: center;
  }
  .nav {
    grid-column: 3/7;
    grid-row: 2;
    text-align: left;
    list-style: none;
    z-index: 2;
  }
  li {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 0.8em;
  }
  li:first-child {
    margin-top: 0;
  }
  a {
    text-decoration: none;
    color: gray;
  }
  a:hover {
    color: white;
    cursor: pointer;
  }
  .img-ascii {
    grid-column: 4/7;
    grid-row: 1;
    font-size: 6px;
  }

  .text-part {
    grid-row: 1/3;
    grid-column: 8/11;
    align-self: center;
    transform: translateY(-50px);
  }
  .desc {
    text-align: left;
  }
  .heading {
    margin-bottom: 20px;
    width: 50%;
    text-align: left;
  }

  .about {
    text-align: right;
    margin-top: 10px;
  }
</style>
