<template>
  <div class="page">
    <Navigation class="navigation" />
    <div class="container">

      <div class="wrapper">
        <img src="../assets/IgnisFaatus/IMG_2984.jpg" class="first img"/>
        <div class="text__wrapper">
          <h1 class="heading">Ignis Faatus</h1>
          <h2 class="sub-heading">Light installation in a field, 2021</h2>
          <p class="desc">
  In collaboration with another latvian based artist Sandijs Ruluks, we were commissioned to create artwork for the annual music and arts festival "Sansusi", taking place in Akniste, Latvia. For this years festival, we explored the phenomenon called Ignis Faatus and its references in the famous latvian play “Spriditis”. Here the protagonist was lured in the swamp by the flickering lights, which he thought was the shimmering of the gold coins. Our end piece was a generative nightly light installation in a wheat field.  The visitors were drawn into the field by the meditative movement of the mystical lights. Once in the field, the outside world gradually faded away, allowing the visitors to get lost in between the real and the imagined.
          </p>
        </div>
        <img src="../assets/IgnisFaatus/IMG-3318.png" class="third img"/>
        <img src="../assets/IgnisFaatus/IMG-3317.png" class="second img"/>
        <video ref="videoPlayer" class="video vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
        </div>
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/navigation.vue'

import videojs from 'video.js';

export default {
  name: 'IgnisFaatus',
  props: {
    msg: String
  },
  data() {
    return {
      player: null,
      videoOptions: {
        autoplay: false,
        controls: true,
        loop: true,
        sources: [
          {
            src: "https://data-store.xyz/video/ignisFaatus/maldugunis_fin_04.mp4",
            type: "video/mp4",
          },
        ]
      }
    }
  },
  components: {
    Navigation
  },
  methods: {},
  mounted(){
    console.log(this.videoOptions)
    this.player = videojs(this.$refs.videoPlayer, this.videoOptions,
        function onPlayerReady() {
            console.log('onPlayerReady', this);
        })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .wrapper {
    grid-template-rows: auto auto auto auto;
    row-gap: 50px;
  }
  .first.img {
    grid-column: 8 / 11;
    grid-row: 1;
    align-self: flex-end;
    width: 110%;
  }
  .second.img {
    width: 110%;
    grid-column: 8 / 13;
    grid-row: 2;
  }
  .third.img {
    grid-column: 1 / 8;
    grid-row: 2;
    width: 93%;
    transform: translateY(-48px);

  }
  .text__wrapper {
    grid-column: 2 / 7;
    grid-row: 1;
    text-align: left;
  }
  .heading, .sub-heading, .desc {
    text-align: right;
  }
  .video {
    grid-row: 3;
    grid-column: 2 / 10;
  }
</style>
