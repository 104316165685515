<!-- eslint-disable -->

<template>

  <div class="page">
    <Navigation class="navigation" />
    <div class="container">
  <!--  <Navigation class="navigation" /> -->

    <div class="wrapper">
        <!--<img src="../assets/divine/011.png" class="head img"/> -->
        <div class="title-row">
          <h1 class="heading">Encounters At<br>The Other Side</h1>
          <h2 class="sub-heading">Interactive Installation, 2023</h2>
        </div>
        <div class="row desc">
          <p class="desc">
            In our dynamic and turbulent age, every keystroke, every digital footprint we leave on the social media landscape, is meticulously chronicled. Stored in endless databases, this data becomes the breeding ground for increasingly sophisticated AI algorithms, growing more intelligent by the fraction of a second. In the physical world too, we are under the constant gaze of countless electronic eyes that see through the lenses of CCTV cameras. This relentless surveillance begs a question: who are these entities, these silicon-based algorithms constantly feeding on our data, an invaluable currency of the present day? What form do they take?<br/><br/>

"Encounters At The Other Side" ventures into the intangible realm of human and AI symbiosis, it attempts to illuminate and personify the faceless algorithms that have seeped into the fabric of our lives, becoming as commonplace as the air we breathe.
The interactive audiovisual installation consists of an artificial neural network that runs in realtime and generates continuous videostream of eerie human-like entities and an accompanying soundscape. When sensing human presence via Leap Motion controller, these entities emerge from the abstract realms of the underlying data structures, gradually assuming human-like characteristics. A simple hand movement allows visitors to engage with these artificial beings, igniting a profound, intimate relationship.<br/><br/>

This symbiotic interaction reminds of the power we humans hold. The algorithms that monitor, learn, and evolve rely on our data for their existence. We are, therefore, not just passive subjects, but active participants, the creators who breathe life into these algorithms. It's a demonstration that our data, our experiences, give these digital beings their form and purpose.

          </p>
        </div>

        <div class="row first">
          <video ref="videoPlayer1" class="video1 vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
          <h4 class="caption"> Recording of the live video projections</h4>
        </div>

        <div class="row second">
            <p class="short-desc">The audiovisual installation consists of PC running custom software written by the artist and Leap Motion sensor. When the sennsor detects the presence of a hand, the AI generated images begin to transform to respond to the viewer's presence. Likewise, the hand movement also trigers and alters the dynamic soundscape that is played in the space in realtime.</p>
        </div>

        <div class="row third">
          <video ref="videoPlayer2" class="video2 vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
          <h4 class="caption"> Recording of a viewer interacting with the installation </h4>
        </div>

        <div class="row fourth">
          <video ref="videoPlayer3" class="video3 vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
          <h4 class="caption"> Recording of a viewer interacting with the installation </h4>
        </div>

        <div class="row fifth">
          <video ref="videoPlayer4" class="video4 vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
          <h4 class="caption"> Recording of a viewer interacting with the installation </h4>
        </div>

        <div class="row sixth">
          <video ref="videoPlayer5" class="video5 vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
          <h4 class="caption"> Exhibition view from ISSP Gallery, Riga, Latvia </h4>
        </div>

        <div class="row seventh comment">
          <img src="../assets/synthetic/IMG-3509.jpg" class="img first"/>
          <p class="short-desc">By moving the hand up, down and sideways by the Leap Motion controller, the viewer is able to control various parameters of the realtime AI image generation, resulting in a life-like dynamic video stream.</p>
        </div>

        <div class="row eight">
          <video ref="videoPlayer6" class="video6 vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
          <h4 class="caption">Leap Motion controller</h4>
        </div>
<!--
        <div class="row ninth comment">
          <div class="video__wrapper">
            <video ref="videoPlayer7" class="video7 vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
          </div>
          <p class="short-desc">Similarly Leap Motion also controls the hardware synthesizer that is connected via MIDI cable. Depending on the viewer’s hand position, different notes are being played. This coupled with the effects pedal creates layered sound collages. </p>
        </div>

        <div class="row tenth comment">
          <p class="short-desc">Touchdesigner software is used as a bridge to connect Leap Motion with the custom software that generates AI images as well as the hardware synthesiser. It also manipulates the real time video feed by adding the subtle liquid motion effect. </p>
          <div class="video__wrapper">
            <video ref="videoPlayer8" class="video8 vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
          </div>
        </div>
-->
        <div class="row ninth">
          <img src="../assets/synthetic/instalacija-1-rote.jpg" class img="img second"/>
          <h4 class="caption">Exhibition view from ISSP Gallery, Riga, Latvia</h4>
        </div>
    </div>
  </div>
</div>
</template>

<script>
/* eslint-disable */

import Navigation from '@/components/navigation.vue'
import videojs from 'video.js';

export default {
  name: 'Synthetic',
  props: {
    msg: String
  },
  components: {
    Navigation
  },
  data() {
    return {
      videoOptions1: {
        autoplay: 'muted',
        controls: false,
        mute: true,
        loop: true,
        sources: [
          {
            src: "https://data-store.xyz/video/synthetic/TD-render.mov",
            type: "video/mp4",
          },
        ]
      },
      videoOptions2: {
        autoplay: 'muted',
        controls: false,
        preload: 'auto',
        mute: true,
        loop: true,
        sources: [
          {
            src: "https://data-store.xyz/video/synthetic/Doc-1.m4v",
            type: "video/mp4",
          },
        ]
      },
      videoOptions3: {
        autoplay: 'muted',
        controls: false,
        loop: true,
        mute: true,
        sources: [
          {
            src: "https://data-store.xyz/video/synthetic/Doc-2.m4v",
            type: "video/mp4",
          },
        ]
      },
      videoOptions4: {
        autoplay: 'muted',
        controls: false,
        loop: true,
        mute: true,
        sources: [
          {
            src: "https://data-store.xyz/video/synthetic/Doc-3.m4v",
            type: "video/mp4",
          },
        ]
      },
      videoOptions5: {
        autoplay: false,
        controls: true,
        loop: false,
        mute: false,
        sources: [
          {
            src: "https://data-store.xyz/video/synthetic/Issp_Ecounters-Full.m4v",
            type: "video/mp4",
          },
        ]
      },
      videoOptions6: {
        autoplay: 'muted',
        controls: false,
        mute: true,
        loop: true,
        sources: [
          {
            src: "https://data-store.xyz/video/synthetic/Leapmotion-Rokas.m4v",
            type: "video/mp4",
          },
        ]
      },
      videoOptions7: {
        autoplay: 'muted',
        controls: false,
        mute: true,
        loop: true,
        sources: [
          {
            src: "https://data-store.xyz/video/synthetic/Audio-Equipment.m4v",
            type: "video/mp4",
          },
        ]
      },
      videoOptions8: {
        autoplay: 'muted',
        controls: false,
        mute: true,
        loop: true,
        sources: [
          {
            src: "https://data-store.xyz/video/synthetic/Kompis-TD.m4v",
            type: "video/mp4",
          },
        ]
      },
    }
  },
  mounted(){
    this.player = videojs(this.$refs.videoPlayer1, this.videoOptions1)
    this.player = videojs(this.$refs.videoPlayer2, this.videoOptions2)
    this.player = videojs(this.$refs.videoPlayer3, this.videoOptions3)
    this.player = videojs(this.$refs.videoPlayer4, this.videoOptions4)
    this.player = videojs(this.$refs.videoPlayer5, this.videoOptions5)
    this.player = videojs(this.$refs.videoPlayer6, this.videoOptions6)
    this.player = videojs(this.$refs.videoPlayer7, this.videoOptions7)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .container {
    width: 100vw !important;
  }
  .wrapper {
    padding-bottom: 100px;
    width: 100%;
    row-gap: 80px;

    @media only screen and (max-width: 600px) {
      row-gap: 25px;
      padding-bottom: 0;
    }
  }

  p {
    text-align: justify;
  }

  .row.comment {
    display: flex;
    align-items: center;
  }
  .title-row {
    grid-row: 1;
    grid-column: 3/7;
    transform: translate(95px,50px);
    text-align: left;

    @media only screen and (max-width: 600px) {
      transform: none;
    }
  }

  .row.desc {
    grid-row: 2;
    grid-column: 3/8;
  }

  .row.first {
    grid-row: 3;
    grid-column: 4/10;

    @media only screen and (max-width: 600px) {
      width: 100%;

      .caption {
        display: none;
      }
    }
  }

  .row.second {
    grid-row: 4;
    grid-column: 2/5;

    @media only screen and (max-width: 600px) {
      width: 100%;
      margin-top: 75px;
      margin-bottom: 75px;

      .caption {
        display: none;
      }
    }

  }

  .row.third {
    grid-row: 5;
    grid-column: 4/12;

    @media only screen and (max-width: 600px) {
      width: 100%;

      .caption {
        display: none;
      }
    }

  }

  .row.fourth {
    grid-row: 6;
    grid-column: 2/10;

    @media only screen and (max-width: 600px) {
      width: 100%;
      transform: translateY(-20px);


      .caption {
        display: none;
      }
    }
  }

  .row.fifth {
    grid-row: 7;
    grid-column: 4/12;
    display: none;
  }
  .row.sixth {
    grid-row: 8;
    grid-column: 4/12;

    @media only screen and (max-width: 600px) {
      width: 100%;
      margin-top: 50px;

      .caption {
      }
    }

  }

  .row.seventh {
    grid-row: 9;
    grid-column: 2/12;

    @media only screen and (max-width: 600px) {
      flex-wrap: wrap;
      margin-top: 75px;
    }

    img {
      width: 60%;

      @media only screen and (max-width: 600px) {
        width: 100%;
      }
    }

    p {
      width: 30%;
      margin-left: 75px;

      @media only screen and (max-width: 600px) {
        width: 100%;
        margin: 0;
        margin-top: 25px;
      }
    }
  }

  .row.eight {
    grid-row: 10;
    grid-column: 4/12;

    @media only screen and (max-width: 600px) {
      width: 100%;

      .caption {
        display: none;
      }
    }
  }

  .row.ninth {
    grid-row: 11;
    grid-column: 2/7;

    @media only screen and (max-width: 600px) {
      width: 100%;
      margin-top: 150px;
    }


    img {
      width: 100%;
    }
  }

  .row.tenth {
    grid-row: 12;
    grid-column: 2/12;

    .video__wrapper {
      width: 60%;
    }

    p {
      width: 30%;
      margin-right: 75px;
    }
  }










</style>
