<template>
  <div class="page">
    <Navigation class="navigation" />
    <div class="container">

      <div class="wrapper">
        <div class="text__wrapper">
          <h1 class="heading">Room With a View</h1>
          <h2 class="sub-heading">AR, 2020</h2>
          <p class="desc">
              A project done in the first months of the Covid-19 pandemic. Using a dedicated website and scanning a special postcard, users are transported to a secluded paradise located on a Caribbean island. The livestream from the paradise includes audio feed so one can hear the ocean waves playing in the wind.
          </p>
        </div>
        <img src="../assets/roomwithview/postcard.png" class="first img"/>
        <img src="../assets/roomwithview/1.png" class="second img"/>
        <img src="../assets/roomwithview/2.png" class="third img"/>
        <video ref="videoPlayer" class="video vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>

        </div>
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/navigation.vue'
import videojs from 'video.js';

export default {
  name: 'RoomWithView',
  components: {
    Navigation
  },
  props: {
    msg: String
  },
  data() {
    return {
      player: null,
      videoOptions: {
        autoplay: false,
        controls: true,
        sources: [
          {
            src: "https://data-store.xyz/video/roomWithView/screenrec_room_1.mp4",
            type: "video/mp4",
          },
        ]
      }
    }
  },
  methods: {},
  mounted(){
    console.log(this.videoOptions)
    this.player = videojs(this.$refs.videoPlayer, this.videoOptions,
        function onPlayerReady() {
            console.log('onPlayerReady', this);
        })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .wrapper {
    grid-template-rows: auto auto auto;
  }
  .first.img {
    grid-column: 1 / 7;
    grid-row: 2;
    transform: translateY(30px);
  }
  .second.img {
    grid-column: 7 / 12;
    grid-row: 1;
    width: 90%;
    transform: translateX(-50px);
  }
  .third.img {
    grid-column: 7 / 12;
    grid-row: 2;
    align-self: flex-end;
  }
  .heading {
    grid-column: 4 / 6;
    grid-row: 1;
    align-self: center;
    text-align: right;
  }
  .text__wrapper {
    grid-column: 2 / 6;
    grid-row: 1;
    text-align: right;
  }
  .video {
    grid-column: 2/10;
    grid-row: 3;
    margin-top: 50px;
  }
</style>
