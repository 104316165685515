<!-- eslint-disable -->

<template>

  <div class="page">
    <Navigation class="navigation" />
    <div class="container">
  <!--  <Navigation class="navigation" /> -->

    <div class="wrapper">
        <!--<img src="../assets/divine/011.png" class="head img"/> -->
        <div class="title-row row">
          <h1 class="heading">Memories From a<br>Distant Future</h1>
          <h2 class="sub-heading">Two-channel video, 2023</h2>
          <p class="desc">
            Inspired by Italo Calvino's book Invisible Cities, Memories of The Distant Future delves into the fluidity of memory and the transformative nature of perception.<br/>
            Utilizing LIDAR-scanned subjects from the author's personal archive, generative AI is employed to reimagine these scans, infusing them with fragments of imaginary Venetian architecture and other elements akin to Invisible Cities.
            This fusion not only mirrors the ever-evolving nature of our memories but also underscores the impact of AI on our cognitive processes.<br/>
            Just as Marco Polo's cities morph and evolve in the mind's eye, our memories, too, are not static; they are living entities, constantly reshaped by time, experience, and now, by the algorithms that increasingly mediate our reality.
            The piece serves as a meditation on the malleability of memory in the digital age, where the boundaries between the real, the remembered, and the AI-generated begin to blur.
          </p>
        </div>

        <div class="row first">
          <video ref="videoPlayerScan1" class="videoScan1 vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
          <video ref="videoPlayerScan2" class="videoScan2 vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
          <video ref="videoPlayerScan3" class="videoScan3 vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
        <!--  <img src="../assets/memories/dzhess-kapnes.png" class="img first"/>
          <img src="../assets/memories/dzhess-koka.png" class="img second"/>
          <img src="../assets/memories/ziedi.png" class="img third"/> -->

          <p class="desc">
            POLO: Perhaps this garden exists only in the shadow of our lowered eyelids, and we have never stopped: you, from raising dust on the fields of battle; and I, from bargaining for
            sacks of pepper in distant bazaars. But each time we half- close our eyes, in the midst of the din and the throng, we are allowed to withdraw here, dressed in silk kimonos, to ponder what we are seeing and living, to draw conclusions, to contemplate from the distance.
          </p>

      <!--    <h4 class="caption"> Recording of the live video projections</h4>-->
        </div>

        <div class="row second">
          <img src="../assets/memories/sd-ziedi.png" class="img first"/>
          <img src="../assets/memories/sd-pludmale.png" class="img second"/>
          <img src="../assets/memories/sd-koka.png" class="img third"/>
        </div>

        <div class="row third">
          <video ref="videoPlayer1" class="video1 vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
          <video ref="videoPlayer2" class="video2 vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
        </div>

        <div class="row fourth">
          <p class="desc">
            POLO: Perhaps the terraces of this garden overlook only the lake of our mind.
            KUBLAI: And however far our troubled enterprises as warriors and merchants may take us, we both harbour within ourselves this silent shade, this conversation of
            pauses, this evening that is always the same.
            POLO: Unless the opposite hypothesis is correct: that those who strive in camps and ports exist only because we two think of them, here, enclosed among these bamboo hedges, motionless since time began.
          </p>
        </div>

        <div class="row fifth">
          <video ref="videoPlayer3" class="video3 vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
        </div>


    </div>
  </div>
</div>
</template>

<script>
/* eslint-disable */

import Navigation from '@/components/navigation.vue'
import videojs from 'video.js';


export default {
  name: 'Memories',
  props: {
    msg: String
  },
  components: {
    Navigation
  },
  data() {
    return {
      videoOptions1: {
        autoplay: 'muted',
        controls: false,
        mute: true,
        loop: true,
        sources: [
          {
            src: "https://data-store.xyz/video/memories/sd-dzhesskapnes.mp4",
            type: "video/mp4",
          },
        ]
      },
      videoOptions2: {
        autoplay: 'muted',
        controls: false,
        preload: 'auto',
        mute: true,
        loop: true,
        sources: [
          {
            src: "https://data-store.xyz/video/memories/sd-skupsts.mp4",
            type: "video/mp4",
          },
        ]
      },
      videoOptions3: {
        autoplay: false,
        controls: true,
        poster: "https://data-store.xyz/imgs/memories/cities-and-dreams-screen.png",
        loop: true,
        mute: false,
        sources: [
          {
            src: "https://data-store.xyz/video/memories/cities-and-dreams.mp4",
            type: "video/mp4",
          },
        ]
      },
      videoOptionsScan1: {
        autoplay: 'muted',
        controls: false,
        mute: true,
        loop: true,
        sources: [
          {
            src: "https://data-store.xyz/video/memories/scan-dzhess.mov",
            type: "video/mp4",
          },
        ]
      },
      videoOptionsScan2: {
        autoplay: 'muted',
        controls: false,
        mute: true,
        loop: true,
        sources: [
          {
            src: "https://data-store.xyz/video/memories/scan-supultikla.mov",
            type: "video/mp4",
          },
        ]
      },
      videoOptionsScan3: {
        autoplay: 'muted',
        controls: false,
        mute: true,
        loop: true,
        sources: [
          {
            src: "https://data-store.xyz/video/memories/scan-ziedi.mov",
            type: "video/mp4",
          },
        ]
      },
    }
  },
  methods: {
    loadPCDs: function(){
      //const PLYLoader = require('three-ply-loader');
      //const loader = new PLYLoader();
    }
  },
  mounted(){
    this.player = videojs(this.$refs.videoPlayer1, this.videoOptions1)
    this.player = videojs(this.$refs.videoPlayer2, this.videoOptions2)
    this.player = videojs(this.$refs.videoPlayer3, this.videoOptions3)
    this.player = videojs(this.$refs.videoPlayerScan1, this.videoOptionsScan1)
    this.player = videojs(this.$refs.videoPlayerScan2, this.videoOptionsScan2)
    this.player = videojs(this.$refs.videoPlayerScan3, this.videoOptionsScan3)
    this.loadPCDs()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .container {
    width: 100vw !important;
  }
  .wrapper {
    padding-bottom: 100px;
    row-gap: 80px;
    display: flex;
    flex-wrap: wrap;

    @media only screen and (max-width: 600px) {
      row-gap: 25px;
      padding-bottom: 0;
    }

    .row {
      display: grid;
      grid-template-columns: repeat(12,1fr);
      column-gap: 25px;
      row-gap: 15px;
      width: 100%;
      max-width: 1600px;
      margin: 0 auto;
      margin-top: 150px;
    }

    .title-row {
      margin-top: 50px;
    }

    .desc {
    }
  }

  .title-row {
    grid-template-rows: auto auto auto;

    .heading {
      grid-column: 4/8;
      grid-row: 1;
      text-align: left;
    }

    .sub-heading {
      grid-row: 2;
      grid-column: 4/6;
      text-align: left;
      margin-top: 0;
    }

    .desc {
      grid-row: 3;
      grid-column: 5/9;
      text-align: justify;
      margin-top: 75px;
      color: #b5a9a9;
    }
  }

  .row.first {
    grid-row-gap: 75px;
    margin-top: 150px;

   .videoScan1 {
     grid-column: 3/6;
     grid-row: 1;
   }

   .videoScan2 {
     grid-column: 7/10;
     grid-row: 1;
     margin-top: 100px;
   }

   .videoScan3 {
     grid-row: 2;
     grid-column: 4/7;
     transform: translateX(-50px);
   }

   .desc {
     grid-row: 2;
     grid-column: 7/10;
     text-align: justify;
     align-self: center;
   }
  }

  .row.second {
    display: flex;

    .img {
      transform: scale(0.8);
    }

    .img.first {
      transform: translateY(50px) scale(0.8);
    }
    .img.third {
      transform: translateY(75px) scale(0.8);
    }
  }

  .row.third {

    .video1, .video2 {
      transform: scale(1.2);
    }

    .video1 {
      grid-column: 3/6;
      transform: translateX(50px) scale(1.2);
    }

    .video2 {
      grid-column: 8/11;
      transform: translateX(-50px) scale(1.2);
    }
  }

  .row.fourth {

    .desc {
      grid-column: 2/5;
      text-align: justify;
    }
  }

  .row.fifth {
    display: flex;
    max-width: none;
  }




</style>
