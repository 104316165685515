<template>
  <div class="nav__container">
    <div class="mobile-nav">
      <li class="item home"><router-link to="/">$_JurgisPeters:</router-link> </li>
      <Slide right>
        <li class="item"><router-link to="/memories"> {{links[0]}}</router-link><span ref="memories" class="check">[ ]</span></li>
        <li class="item"><router-link to="/synthetic"> {{links[1]}}</router-link><span ref="synthetic" class="check">[ ]</span></li>
        <li class="item"><router-link to="/imagesofdivine"> {{links[2]}}</router-link><span ref="imagesofdivine" class="check">[ ]</span></li>
        <li class="item"><router-link to="/newtrajectories"> {{links[3]}}</router-link><span ref="newtrajectories" class="check">[ ]</span></li>
        <li class="item"><router-link to="/alternative"> {{links[4]}}</router-link><span ref="alternative" data-value="alternative" class="check">[ ]</span></li>
        <li class="item"><router-link to="/possibilities">{{links[5]}}</router-link><span ref="possibilities" class="check">[ ]</span> </li>
        <li class="item"><router-link to="/familiar">{{links[6]}}</router-link><span ref="familiar" class="check">[ ]</span> </li>
        <li class="item"><router-link to="/aisoundscapes">{{links[7]}}</router-link><span ref="aisoundscapes" class="check">[ ]</span> </li>
        <li class="item"><router-link to="/suggested"> {{links[8]}}</router-link><span ref="suggested" class="check">[ ]</span> </li>
        <li class="item"><router-link to="/ignisfaatus"> {{links[9]}}</router-link><span ref="ignisfaatus" class="check">[ ]</span></li>
        <li class="item"><router-link to="/connectednest"> {{links[10]}}</router-link><span ref="connectednest" class="check">[ ]</span></li>
        <li class="item"><router-link to="/roomwithaview">{{links[11]}}</router-link><span ref="roomwithaview" class="check">[ ]</span></li>
        <li class="item about"><router-link to="/about">about</router-link><span ref="about" class="check">[ ]</span></li>
      </Slide>
    </div>
    <div class="nav__wrapper">
      <li class="item home"><router-link to="/">$_JurgisPeters:</router-link> </li>
      <li class="item"><router-link to="/memories"> {{links[0]}}</router-link><span ref="memories" class="check">[ ]</span></li>
      <li class="item"><router-link to="/synthetic"> {{links[1]}}</router-link><span ref="synthetic" class="check">[ ]</span></li>
      <li class="item"><router-link to="/imagesofdivine"> {{links[2]}}</router-link><span ref="imagesofdivine" class="check">[ ]</span></li>
      <li class="item"><router-link to="/newtrajectories"> {{links[3]}}</router-link><span ref="newtrajectories" class="check">[ ]</span></li>
      <li class="item"><router-link to="/alternative"> {{links[4]}}</router-link><span ref="alternative" data-value="alternative" class="check">[ ]</span></li>
      <li class="item"><router-link to="/possibilities">{{links[5]}}</router-link><span ref="possibilities" class="check">[ ]</span> </li>
      <li class="item"><router-link to="/familiar">{{links[6]}}</router-link><span ref="familiar" class="check">[ ]</span> </li>
      <li class="item"><router-link to="/aisoundscapes">{{links[7]}}</router-link><span ref="aisoundscapes" class="check">[ ]</span> </li>
      <li class="item"><router-link to="/suggested"> {{links[8]}}</router-link><span ref="suggested" class="check">[ ]</span> </li>
      <li class="item"><router-link to="/ignisfaatus"> {{links[9]}}</router-link><span ref="ignisfaatus" class="check">[ ]</span></li>
      <li class="item"><router-link to="/connectednest"> {{links[10]}}</router-link><span ref="connectednest" class="check">[ ]</span></li>
    <!--  <li class="item"><router-link to="/livingnoise"> {{links[9]}}</router-link><span ref="livingnoise" class="check">[ ]</span></li> -->
      <li class="item"><router-link to="/roomwithaview">{{links[11]}}</router-link><span ref="roomwithaview" class="check">[ ]</span></li>
      <li class="item about"><router-link to="/about">about</router-link><span ref="about" class="check">[ ]</span></li>
    <!--  <div class="lines"> ~~~ </div> -->
    </div>
    <div class="lines">------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ </div>
  </div>
</template>

<script>
import { Slide }  from 'vue-burger-menu'

export default {
  name: 'Navigation',
  components: {
    Slide
  },
  props: {
    msg: String
  },
  data() {
    return {
      links: [
        'Memories',
        'Encounters_At_The_Other_Side',
        'Images_of_The_Divine',
        'New_Trajectories',
        'Alternative_Realities',
        'Possibilities_of_Life',
        'So_Familiar_yet_so_Strange',
        'AI_Soundscapes',
        'Suggested',
        'Ignis_Faatus',
        'Connected/Nest',
        'Room_With_a_View'
      ],
      windowWidth: window.innerWidth

    }
  },
  methods: {
    setActivePage: function() {
      let route = this.$router.currentRoute
      route = route.path.replace(/\//g, '');

      let el = this.$refs[route]
      el.innerHTML = '[x]'
    },
    onResize() {
      this.windowWidth = window.innerWidth
      if (this.windowWidth < 1921) {
        this.links = [
          'Memories',
          'Encounters',
          'Divine',
          'Trajectories',
          'Realities',
          'Possibilities',
          'Familiar',
          'Soundscapes',
          'Suggested',
          'Ignis',
          'Connected',
          'Room'
        ]
      }
      else {
        this.links = [
          'Memories',
          'Encounters_At_The_Other_Side',
          'Images_of_The_Divine',
          'New_Trajectories',
          'Alternative_Realities',
          'Possibilities_of_Life',
          'So_Familiar_yet_so_Strange',
          'AI_Soundscapes',
          'Suggested',
          'Ignis_Faatus',
          'Connected/Nest',
          'Room_With_a_View'
        ]
      }
    }
  },
  mounted() {
    this.setActivePage()
    this.onResize()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
}
</script>
<style lang="scss">
  .bm-burger-button {
    position: fixed !important;
  }

  .bm-cross-button {
    right: 258px;
    top: 21px;
  }

  .bm-menu {
    background: black;
  }

  .bm-burger-bars {
    background: #b5a9a9;
  }

</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  $highlight: #ffeb00;

  .nav__container {
    width: 100vw;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: flex-start;
  }
  .mobile-nav {
    z-index: 99999;
    margin-left: 40px;
    top: 40px;
    display: none;

    @media only screen and (max-width: 600px) {
      display: block;
    }

    .item.home {
      margin: 0;
      margin-top: 40px;
    }
    .item.about {
      position: inherit;
      right: auto;
      border-top: 2px white dashed;
      margin-top: 25px;
    }
  }
  .nav__wrapper {
    top: 10px;
    display: flex;
    margin-bottom: 50px;
    z-index: 99999;

    flex-wrap: wrap;
    justify-content: center;

    justify-content: flex-start;
    margin-top: 10px;
    padding-bottom: 10px;
    width: 100%;

    @media only screen and (max-width: 600px) {
      display: none;
    }
  }

  .item {
    margin: 10px;
    margin-right: 0;
    font-size: 0.8em;
    list-style: none;
  }
  .item.about {
    position: absolute;
    right: 31px;
  }
  .check {
    margin-left: 5px;
    color: white;
  }
  a {
    text-decoration: none;
    color: gray;
  }
  a:hover {
    color: white;
    cursor: pointer;
  }
  .lines {
    position: absolute;
    top: 45px;
    left: -20px;
    width: 2000px;
    color: #b5a9a9;

    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
  .home {
    font-weight: normal;
    color: white;
    cursor: pointer;
    margin-right: 0;

    a {
      color: unset;
    }

  }

</style>
