<template>
  <div class="section">
    <div class="content">
      <div class="line"></div>
      <div class="t t1">
        <div class="img__container">
          <transition name="fade">
            <img :src="imgs[0]" :key="imgs[0]" data-toggle="tooltip" />
          </transition>
          <!--  <img :src="imgs[0]" /> -->
            <div class="desc__wrapper">
              <div class="desc__container">
                <div class="item" v-for="(val, key) in allItems[0]" v-bind:key="key">
                    <vue-typed-js :strings="[`${key}: ${val}`]" :typeSpeed="10" :showCursor="false">
                      <h3 class="typing title"> </h3>
                    </vue-typed-js>
                      <!--<h3 class="title"> {{ item.name }} </h3>-->
                <!--      <h3 class="score"> {{ item.score }} </h3> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      <div class="t t2">
        <div class="img__container">
          <transition name="fade">
            <img :src="imgs[1]" :key="imgs[1]" data-toggle="tooltip" />
          </transition>
            <img class="wtf" :src="imgSrc" />
        </div>
        <div class="desc__wrapper">
          <div class="desc__container">
            <div class="item" v-for="(val, key) in allItems[1]" v-bind:key="key">
                <vue-typed-js :strings="[`${key}: ${val}`]" :typeSpeed="10" :showCursor="false">
                  <h3 class="typing title"> </h3>
                </vue-typed-js>
                  <!--<h3 class="title"> {{ item.name }} </h3>-->
            <!--      <h3 class="score"> {{ item.score }} </h3> -->
            </div>
          </div>
        </div>
      </div>
  </div>
  </div>
</template>

<script>
/* eslint-disable */
//import PageTitle from '@/components/PageTitle.vue'
const axios = require('axios');
import typewriter from 'vue-type-writer'

export default {
  name: 'SuggestedImgAnimation',
  components: {
      //  PageTitle, Stage, Footer, NavBar
  },
  data () {
    return {
      status: 'hidden',
      serverIp: "https://jurgispeters.xyz",
      classifications: [],
      allItems: [],
      imgSrc: "",
      imgs: [],
      imgDescs: {},
      imgScores: [],
      animSpeed: [4000,4000],
      animate: true,

    }
  },
  methods: {

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    },
    lookupData: async function(){
      let app = this
      console.log(`1212 hello`)
      axios.get(`${app.serverIp}:3005/`)
      .then(async function (response) {
        // handle success
        response.data.forEach(element => {
          let values = {
            'code': element['code'],
            'scores': element['score'],
            'descriptions': element['description'],
            'colours': element['colours']
          }
          app.classifications.push(values)
        });

        // START ANIMATING?
        if (app.animate) {
          app.getItems(0)
          await app.sleep(2000)
          app.getItems(1)
        }
        else {
          app.singleItem(0)
          app.singleItem(1)
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
    },
   padToFour: function(number){
     if (number<=9999) { number = ("000"+number).slice(-4); }
     return number;
   },
   singleItem: function(counter){
     let img = this.classifications[counter]['code']
     let categories = this.classifications[counter]['descriptions'].split(',')
     let scores = this.classifications[counter]['scores'].split(',')
     let imgDesc = {}
     for (var c = 0; c < categories.length; c++) {
        imgDesc[categories[c]] =  Math.round(scores[c] * 1000) / 1000
     }
     img = this.padToFour(img)
     console.log(`img n: ${img}`)
     this.imgSrc = require(`../assets/saved_imgs/${img}.jpg`)
     this.imgs[counter] = this.imgSrc
     this.allItems[counter] = imgDesc
   },
   getItems: async function(counter){
     let app = this
     let len =  this.classifications.length
     let seed = this.getRandom(0, len)
     for (var i = 0; i < len - seed; i++) {
       let img = this.classifications[seed+i]['code']
       let categories = this.classifications[seed+i]['descriptions'].split(',')
       let scores = this.classifications[seed+i]['scores'].split(',')
       let imgDesc = {}
       for (var c = 0; c < categories.length; c++) {
          imgDesc[categories[c]] =  Math.round(scores[c] * 1000) / 1000
       }

        img = this.padToFour(img)
        try {
          app.imgSrc = require(`../assets/saved_imgs/${img}.jpg`)
        }
        catch(e) {
          console.log(e)
        }
        app.allItems[counter] = imgDesc
        app.imgs[counter] = app.imgSrc
        await app.sleep(app.animSpeed[counter])
     }
   },
   getRandom: function(min, max) { // min and max included
     return Math.floor(Math.random() * (max - min + 1) + min);
   }
  },
  computed: {
    sortScores: function(){
      let app = this
      return this.toPrint.sort(function(a, b) {
        return b[1] - a[1];
      });
    }
  },
  mounted(){
    this.lookupData()
  }

}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .section {
    margin: 0 auto;
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .line {
    width: 100%;
    height: 2px;
    background: #f9dd5d;
    position: absolute;
    display: none;
  }
  .t {
    display: flex;
    width: 500px;
    position: relative;
  }
  .t1 {
    margin-right: 45px;
    width: 400px;
    height: 400px;
  }
  .t2 {
    width: 500px;
    height: 500px;
  }
  h3 {
    font-family: 'Courier New', monospace;
    color: black;

  }
  .wtf {
    display: none;
  }
  .img__container {
    width: 100%;
    height: 100%;
    position: relative;

  }
  .img__container img {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s ease;
  }
    .fade-enter, .fade-leave-to
    /* .component-fade-leave-active below version 2.1.8 */ {
      opacity: 0;
    }
  .desc__wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: left;
    display: flex;
    align-items: center;
  }
  /* SCORE ITEMS  */
  .item {
    padding: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 2px;
    margin-bottom: 2px;
    background: #f9dd5db3;
    width: 250px;
  }
  .item h3 {
    display: inline-block;
    margin: 0;
    font-size: 0.8em;

  }
  .item .title {
    padding: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .item .score {
    padding: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .img__container img {

  }

</style>
