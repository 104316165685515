<template>
  <div class="page">
    <Navigation class="navigation" />
    <div class="container">
      <div class="wrapper">
        <div class="top__part">
          <!--<img src="../assets/soStrange/nn4.png" class="first img"/> -->
          <div class="text__wrapper">
            <h1 class="heading">So Familiar Yet so Strange</h1>
            <h2 class="sub-heading">AR, 2021</h2>
            <p class="desc">An AR piece created for the group exhibition "Augmented Experiences", organized by the RIXC gallery. <br /><br/>
    The four different stages of the author's portrait depict the Artificial Intelligence (AI) training process creating a synthetic image which is indistinguishable from the original. Various globally connected AI algorithms are increasingly capable of performing more different tasks, and becoming more self-aware. Ubiquitous algorithms are taking over tasks traditionally reserved for human judgment such as calculating individual credit scores or flying military drones. How far are we from achieving Superintelligence? And how will it affect human experience and perception of reality – if our ability to distinguish the 'real world' images from those generated by Artificial General Intelligence will be lost.
            </p>
          </div>
          <model-viewer class="first img" alt="" src="https://data-store.xyz/3d/soStrange/face.glb" ar ar-modes="webxr scene-viewer quick-look" camera-orbit="100deg 100deg 50m" seamless-poster shadow-intensity="1" camera-controls></model-viewer>
        </div>
        <video ref="videoPlayer" class="video vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>
        <img src="../assets/soStrange/SO-strange-1-alpha.png" class="second img"/>
        <!--<img src="../assets/soStrange/01-020.png" class="second img"/>
        <img src="../assets/soStrange/second-020.png" class="third img"/>
        <img src="../assets/soStrange/01-028.png" class="fourth img"/>
        <img src="../assets/soStrange/01-143.png" class="fifth img"/> -->
        <img src="../assets/soStrange/edit.png" class="sixth img"/>

        <h4 class="caption first">AI training process </h4>

        <img src="../assets/soStrange/full.png" class="seventh img"/>

        <h4 class="caption second">Trained images applied to 3D models </h4>

        <video ref="videoPlayerRec" class="video__rec vjs-default-skin vjs-fluid video-js vjs-big-play-centered" ></video>

        <h4 class="caption third">screen recording of AI portraits in AR </h4>

        </div>
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/navigation.vue'
import videojs from 'video.js';
import canAutoPlay from 'can-autoplay';

export default {
  name: 'SoStrange',
  props: {
    msg: String
  },
  components: {
    Navigation
  },
  data() {
    return {
      player: null,
      autoplay: true,
      videoOptions: {
        autoplay: 'muted',
        controls: false,
        mute: true,
        loop: true,
        sources: [
          {
            src: "https://data-store.xyz/video/soStrange/training_vid-faster.mp4",
            type: "video/mp4",
          },
        ]
      },
      videoOptionsRec: {
        autoplay: 'muted',
        controls: false,
        mute: true,
        loop: true,
        sources: [
          {
            src: "https://data-store.xyz/video/soStrange/rixx_sejas.mov",
            type: "video/mp4",
          },
        ]
      }
    }
  },
  methods: {
    initVideos: function(){
      if (!this.autoplay) {
        this.videoOptions.controls = true
        this.videoOptionsRec.controls = true
      }
      this.player = videojs(this.$refs.videoPlayer, this.videoOptions,
          function onPlayerReady() {
              console.log('onPlayerReady', this);
          })
      this.playerRec = videojs(this.$refs.videoPlayerRec, this.videoOptionsRec,
          function onPlayerReady() {
              console.log('onPlayerReady', this);
          })
    }
  },
  mounted(){
    let self = this
    canAutoPlay.video().then(function(obj) {
      if (obj.result === true) {
        self.autoplay = true
        // Can autoplay
      } else {
        self.autoplay = false
      }
      self.initVideos()
    });

  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$red: #6c0909;

  .wrapper {
    grid-template-rows: auto auto auto auto auto auto;
    grid-row-gap: 100px;
  }
  .text__wrapper {
    width: 100%;
    text-align: left;

    .desc {
      text-align: justify;
      margin-top: 90px;
    }

    .sub-heading {
      text-align: right;
    }
  }
  .top__part {
    grid-column: 2 / 11;
    grid-row: 1 / 3;
    display: flex;
    margin-bottom: 100px;
    margin-top: 50px;
  }
  .video {
    grid-column: 1/11;
    grid-row: 3 / 4;
  }
  .first.img {
    height: 100%;
    min-height: 500px;
    transform: translateX(44px);

  }
  .second.img {
    grid-column: 2 / 8;
    grid-row: 4;
  }
  .third.img {
    grid-column: 5 / 7;
    grid-row: 4;
    width: 110%;
    transform: translateX(-20px);
  }
  .fourth.img {
    grid-column: 2 / 4;
    grid-row: 5;
    width: 136%;
    transform: translateX(40px);
  }
  .fifth.img {
    grid-column: 5 / 7;
    grid-row: 5;
    transform: translateX(39px);
    width: 110%;
  }
  .sixth.img {
    grid-column: 8 / 12;
    grid-row: 4 / 6;
    transform: translateY(64px);
  }
  .seventh.img {
    grid-column: 1 / 13;
    grid-row: 6;

    margin-top: 50px;
  }
  .video__rec {
    grid-row: 7;
    grid-column: 1/11;

    margin-top: 50px;
  }
  .heading {
    grid-column: 4 / 6;
    grid-row: 1;
    align-self: center;
    text-align: right;
  }

  .caption.first {
    grid-column: 1/13;
    grid-row: 6;
    z-index: 9;
    transform: translateY(-50px);
  }
  .caption.second {
    grid-row: 7;
    grid-column: 1/13;
    z-index: 9;
    margin: 0;
    transform: translateY(-50px);
  }
  .caption.third {
    grid-row: 8;
    grid-column: 1/13;
    z-index: 9;
    margin: 0;
    transform: translateY(-50px);
  }
</style>
